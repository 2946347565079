import { Slider, Spin } from "antd"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { fabric } from 'fabric';
import Utils from '@utils/utils'
import styles from './Drag.module.scss'
import { colorPrimary, colorCenterLine } from '@utils/AppContext';
import { GenerateContext } from "@utils/GenerateContext";
import * as _ from "underscore";
import Decimal from 'decimal.js'

const toFixed = (num, precision=4) => {
    return Number((new Decimal(num)).toFixed(precision))
}

const ZoomBg = (props) => {

    return (
        <div className={styles.ZoomBg}>
            <span>背景图尺寸</span>
            <div className={styles.ZoomSlider}>
                <span>小</span>
                <Slider value={props.value} min={1} max={1.5} step={.1} tooltip={{open: false}} onChange={props.onChange}/>
                <span>大</span>
            </div>
        </div>
    )
}

// 马赛克
const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';

const Drag = (props) => {
    const {
        bgImage,
        segmentResult,
        imgSize,
        imgClipSize,
        bgSize,
        bgClipSize,
        canvasSize,
        canvasClipSize,
    } = props

    const {
        ltXRef, setLtX,
        ltYRef, setLtY,
        scaleRateRef, setScaleRate,
        bgStyle,
        coordDragRef, setCoordDrag,
    } = useContext(GenerateContext)

    const [loading, setLoading] = useState(false)
    const canvasRef = useRef()
    const fabricCanvasRef = useRef()
    const [fabricCanvas, setFabricCanvas] = useState()
    // 画布跟随窗口缩放
    useEffect(() => {
        if (!fabricCanvas) return
        const oldWidth = fabricCanvas.width
        const oldHeight = fabricCanvas.height
        fabricCanvas.setDimensions({ width: canvasSize.width, height: canvasSize.height })
        // 修改canvas中对象的位置和大小
        if (oldWidth && oldHeight) {
            fabricCanvas.forEachObject((obj) => {
                const scaleFactorX = Decimal.div(canvasSize.width, oldWidth)
                const scaleFactorY = Decimal.div(canvasSize.height, oldHeight)
                obj.left = Decimal.mul(obj.left, scaleFactorX).toNumber()
                obj.top = Decimal.mul(obj.top, scaleFactorY).toNumber()
                obj.scaleX = Decimal.mul(obj.scaleX, scaleFactorX).toNumber()
                obj.scaleY = Decimal.mul(obj.scaleY, scaleFactorX).toNumber()    
                // 更新对象的位置和大小
                obj.setCoords()   
            })
        }
        fabricCanvas.renderAll()
    }, [fabricCanvas, canvasSize])
    // 初始化
    useEffect(() => {
        setLoading(true)
        initCanvasInstance().then(() => {
            setLoading(false)
        })
    }, [])
    // 初始化画布
    const initCanvasInstance = () => {
        return new Promise((resolve) => {
            const instance = new fabric.Canvas(canvasRef.current, {
                width: canvasSize.width,
                height: canvasSize.height,
                renderOnAddRemove: true,
                preserveObjectStacking: true, // 保持画布上对象堆叠顺序
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                isDrawingMode: false, // 禁用绘图模式
                selection: false, // 禁用组选择器
            })
            fabricCanvasRef.current = instance
            setFabricCanvas(fabricCanvasRef.current)
            resolve()
        })
    }


    /**********************加载模特*************************/
    // 加载模特
    useEffect(() => {
        if (!fabricCanvas) return
        const loadMask = (img) => {
            // 修改缩放框角的样式
            img.set({
                opacity: 0,
                cornerColor: '#ffffff',
                cornerFill: '#ffffff', // 设置控制点的填充色为白色
                cornerStrokeColor: colorPrimary, // 设置控制点的边框颜色为#00a0ff
                borderColor: colorPrimary,     // 边框颜色
                cornerSize: 8,            // 角落大小
                transparentCorners: false, // 角落是否透明
                cornerStyle: 'circle',     // 角落样式，可选值有 'rect' 和 'circle'
                lockRotation: false,        // 禁用旋转功能
                lockScalingFlip: true // 禁用镜像翻转
            })
            // 将控制点设置为不可见
            img.setControlsVisibility({
                mt: false, // 隐藏上边中心点
                mb: false, // 隐藏下边中心点
                ml: false, // 隐藏左边中心点
                mr: false, // 隐藏右边中心点
                mtr: false // 隐藏旋转点
            });
            img.set('is_model', true)
            fabricCanvas.add(img);
            fabricCanvas.renderAll();
            fabricCanvas.moveTo(img, 1)
            img.on('modified', ev => {
                checkModelImgBounding(img)
                getModelImgParams(img)            
            })
            img.on('scaling', ev => {
                const canvasWidth = canvasClipSizeRef.current.width
                const canvasHeight = canvasClipSizeRef.current.height
                // 限制最小缩放
                const minScale = 0.5
                // 计算手动缩放
                const scaleX =  Decimal.div(canvasWidth, imgSize.width).toNumber()
                const scaleY = Decimal.div(canvasHeight, imgSize.height).toNumber()
                const scale = Decimal.min(scaleX, scaleY)
                const imgRealScale = Decimal.div(img.scaleX, scale)
                if (imgRealScale.lessThan(minScale)) {
                    let _scale = Decimal.mul(minScale, scale).toNumber()
                    img.scale(_scale)
                    fabricCanvas.renderAll()
                    scaleRateRef.current = _scale
                    setScaleRate(scaleRateRef.current)
                }
            })
        }
        const modelObj = fabricCanvas.getObjects().find(item => item.get('is_model'))
        if (modelObj) fabricCanvas.remove(modelObj)
        Utils.getFrontImage(segmentResult[0], segmentResult[3], segmentResult[1]).then(base64 => {
            Utils.getTrimmedImageBase64(base64).then(res => {
                const {leftTop, rightBottom, originSize, base64} = res
                trimmedImgSize.current = {
                    x: leftTop.x,
                    y: leftTop.y, 
                    width: Decimal.sub(rightBottom.x, leftTop.x).add(1).toNumber(),
                    height: Decimal.sub(rightBottom.y, leftTop.y).add(1).toNumber(),
                    leftPercent: Decimal.div(leftTop.x, originSize.width).toNumber(),
                    rightPercent: Decimal.sub(1, Decimal.div(Decimal.add(rightBottom.x, 1), originSize.width)).toNumber(),
                    topPercent: Decimal.div(leftTop.y, originSize.height).toNumber(),
                    bottomPercent: Decimal.sub(1, Decimal.div(Decimal.add(rightBottom.y, 1), originSize.height)).toNumber(),
                }
                Utils.loadImageUrlByFabric(base64).then(img => {
                    loadMask(img)
                    updateModelForce({})
                })
            })
        })
    }, [fabricCanvas, segmentResult])
    // 切换ratio时重置模特位置，每次ratio或size变化后仅执行一次
    const [modelForce, updateModelForce] = useState()
    const lastImgRatioRef = useRef(props.imgRatio) // 上一次的图片比例
    const lastCanvasSizeRef = useRef(props.canvasSize) // 上一次的CanvasSize
    const lastCanvasClipSizeRef = useRef(props.canvasClipSize) // 上一次的CanvasClipSize
    const lastBgImageRef = useRef(bgImage)
    const debounceResetModel = useCallback(_.debounce((imgRatio, bgImage) => {
        if (!fabricCanvasRef.current) return
        const img = fabricCanvasRef.current.getObjects().find(item => item.get('is_model'))
        if (!img) return

        const canvasWidth = lastCanvasSizeRef.current.width
        const canvasHeight = lastCanvasSizeRef.current.height

        const canvasClipWidth = lastCanvasClipSizeRef.current.width
        const canvasClipHeight = lastCanvasClipSizeRef.current.height

        const scaleX = Decimal.div(canvasClipWidth, imgSize.width) // modelObj.width 
        const scaleY = Decimal.div(canvasClipHeight, imgSize.height) // modelObj.height
        const minScale = Decimal.min(scaleX, scaleY)

        // 通过图片缩放反向计算scale
        let imgScale = minScale.toNumber()
        // 通过模特位移量反向计算left/top
        let left, top
        if (!ltXRef.current && ltXRef.current != 0) { // 新上传的图
            // 模特在canvas中的尺寸
            let modelCanvasWidth = Decimal.mul(img.width, imgScale)
            let modelCanvasHeight = Decimal.mul(img.height, imgScale)
            // 模特周围各区域占比
            const leftPercent = trimmedImgSize.current.leftPercent // 模特左边背景占比
            const rightPercent = trimmedImgSize.current.rightPercent // 模特右边背景占比
            const topPercent = trimmedImgSize.current.topPercent // 模特上边背景占比
            const bottomPercent = trimmedImgSize.current.bottomPercent // 模特下边背景占比
            // 根据左边背景占比计算出模特在canvas的left
            const _leftPercent = Decimal.add(leftPercent, rightPercent).isZero() ? 0 : Decimal.div(leftPercent, Decimal.add(leftPercent, rightPercent))
            const _left = Decimal.mul(Decimal.sub(canvasClipWidth, modelCanvasWidth), _leftPercent)
            const clipToCanvasWidthHalf = Decimal.div(Decimal.sub(canvasWidth, canvasClipWidth), 2)
            left = Decimal.add(_left, clipToCanvasWidthHalf).toNumber()
            // 根据下边背景占比计算出模特在canvas的top
            const _bottomPercent = Decimal.add(bottomPercent, topPercent).isZero() ? 0 : Decimal.div(bottomPercent, Decimal.add(bottomPercent, topPercent))
            const _bottom = Decimal.mul(Decimal.sub(canvasClipHeight, modelCanvasHeight), _bottomPercent)
            const imgAddBottom = Decimal.add(_bottom, modelCanvasHeight)
            const _top = Decimal.sub(canvasClipHeight, imgAddBottom)
            const clipToCanvasHeightHalf = Decimal.div(Decimal.sub(canvasHeight, canvasClipHeight), 2)
            top = Decimal.add(_top, clipToCanvasHeightHalf).toNumber()
        } else {
            imgScale = Decimal.mul(minScale, scaleRateRef.current).toNumber()
            // const imgRealScaleX = toFixed(imgScale / scaleX)
            const imgRealScaleX = Decimal.div(imgScale, scaleX).toNumber()
            // const imgRealScaleY = toFixed(imgScale / scaleY)
            const imgRealScaleY = Decimal.div(imgScale, scaleY).toNumber()
            // const trimmedImgRealX = (ltXRef.current||0) + trimmedImgSize.current.x * imgRealScaleX
            const trimmedImgRealX = Decimal.add((ltXRef.current||0), Decimal.mul(trimmedImgSize.current.x, imgRealScaleX)).toNumber()
            // const trimmedImgRealY = (ltYRef.current||0) + trimmedImgSize.current.y * imgRealScaleY
            const trimmedImgRealY = Decimal.add((ltYRef.current||0), Decimal.mul(trimmedImgSize.current.y, imgRealScaleY)).toNumber()
            // left = toFixed(trimmedImgRealX * scaleX + (canvasWidth - canvasClipWidth) / 2)
            left = Decimal.add(Decimal.mul(trimmedImgRealX, scaleX), Decimal.div(Decimal.sub(canvasWidth, canvasClipWidth), 2)).toNumber()
            // top = toFixed(trimmedImgRealY * scaleY + (canvasHeight - canvasClipHeight) / 2)
            top = Decimal.add(Decimal.mul(trimmedImgRealY, scaleY), Decimal.div(Decimal.sub(canvasHeight, canvasClipHeight), 2)).toNumber()        
        }

        img.set({
            left: left,
            top: top,
        }).scale(imgScale)
        
        img.setCoords()
        fabricCanvasRef.current.renderAll()

        // 需要更新这两个参数，用来计算ModelImgParams
        canvasSizeRef.current = lastCanvasSizeRef.current
        canvasClipSizeRef.current = lastCanvasClipSizeRef.current
        checkModelImgBounding(img)
        getModelImgParams(img)

        lastImgRatioRef.current = imgRatio
        lastBgImageRef.current = bgImage

        img.opacity = 1
        fabricCanvasRef.current.renderAll()

        console.log('===debounceResetModel===')
    }, 500), [])
    useEffect(() => {
        if (!fabricCanvas) return
        // 每次修改
        lastCanvasSizeRef.current = {width: canvasSize.width, height: canvasSize.height}
        lastCanvasClipSizeRef.current = {width: canvasClipSize.width, height: canvasClipSize.height}

        if (lastBgImageRef.current == bgImage && lastImgRatioRef.current != props.imgRatio) {
            console.log('修改图片比例，重置模特位置')
            ltXRef.current = null
            setLtX(ltXRef.current)
            ltYRef.current = null
            setLtY(ltYRef.current)
            coordDragRef.current = null
            setCoordDrag(coordDragRef.current)
            scaleRateRef.current = 1
            setScaleRate(scaleRateRef.current)
        }
        if (!!lastBgImageRef.current && !!bgImage && lastImgRatioRef.current != props.imgRatio) {
            console.log('修改图片比例，重置模特位置')
            ltXRef.current = null
            setLtX(ltXRef.current)
            ltYRef.current = null
            setLtY(ltYRef.current)
            coordDragRef.current = null
            setCoordDrag(coordDragRef.current)
            scaleRateRef.current = 1
            setScaleRate(scaleRateRef.current)
        }
        if (lastBgImageRef.current != bgImage) {
            console.log('修改参考图，重置模特位置')
            ltXRef.current = null
            setLtX(ltXRef.current)
            ltYRef.current = null
            setLtY(ltYRef.current)
            coordDragRef.current = null
            setCoordDrag(coordDragRef.current)
            scaleRateRef.current = 1
            setScaleRate(scaleRateRef.current)
        }
        // 由于会延迟resize，先将model隐藏&取消选中
        const modelObj = fabricCanvasRef.current.getObjects().find(item => item.get('is_model'))
        if (modelObj) {
            modelObj.opacity = 0
            fabricCanvasRef.current.discardActiveObject()
        }

        // 重置模特位置和尺寸
        debounceResetModel(props.imgRatio, bgImage)        
    }, [fabricCanvas, props.imgRatio, bgImage, canvasSize, canvasClipSize, modelForce])

    // 绘制背景
    useEffect(() => {
        if (!fabricCanvas) return
        if (bgImage) {
            const bgObjs = fabricCanvas.getObjects().filter(item => item.get('is_background'))
            fabricCanvas.remove(...bgObjs)
            Utils.loadImageUrlByFabric(bgImage).then(img => {
                const scale = fabricCanvas.width / img.width
                img.scale(scale)
                img.set('is_background', true)
                img.set({
                    hasBorders: false,
                    hasControls: false,
                })
                img.set({
                    originX: 'center',
                    originY: 'center'
                })
                fabricCanvas.add(img)
                
                fabricCanvas.moveTo(img, 0) // 移动到zindex=0
                if (coordDragRef.current) {
                    // 在原图坐标系下裁图区域的位置、宽高
                    const finalX = coordDragRef.current.lt_x
                    const finalY = coordDragRef.current.lt_y
                    const finalW = coordDragRef.current.width
                    const finalH = coordDragRef.current.height

                    const canvasSize = canvasSizeRef.current
                    const canvasClipSize = canvasClipSizeRef.current
                    
                    // 高亮区域坐标 x1、y1（canvas坐标系）
                    const canvasSizeWidth = new Decimal(canvasSize.width)
                    const canvasSizeHeight = new Decimal(canvasSize.height)
                    const canvasClipSizeWidth = new Decimal(canvasClipSize.width)
                    const canvasClipSizeHeight = new Decimal(canvasClipSize.height)
                    // const x1 = canvasSize.width > canvasClipSize.width ? (canvasSize.width - canvasClipSize.width) / 2 : 0
                    const x1 = canvasSizeWidth.greaterThan(canvasClipSizeWidth) ? Decimal.div(Decimal.sub(canvasSizeWidth, canvasClipSizeWidth), 2).toNumber() : 0
                    // const y1 = canvasSize.height > canvasClipSize.height ? (canvasSize.height - canvasClipSize.height) / 2 : 0
                    const y1 = canvasSizeHeight.greaterThan(canvasClipSizeHeight) ? Decimal.div(Decimal.sub(canvasSizeHeight, canvasClipSizeHeight), 2).toNumber() : 0
                    // 背景的缩放比率
                    // const imgScale = canvasClipSize.width / finalW
                    const imgScale = Decimal.div(canvasClipSizeWidth, finalW).toNumber()
                    // 计算出在原图坐标系下裁图区域的左上角坐标
                    // const offsetX = finalX * imgScale
                    const offsetX = Decimal.mul(finalX, imgScale).toNumber()
                    // const offsetY = finalY * imgScale
                    const offsetY = Decimal.mul(finalY, imgScale).toNumber()
                    // 背景左上角的坐标x3、y3（canvas坐标系）
                    // const x3 = x1 - offsetX
                    const x3 = Decimal.sub(x1, offsetX).toNumber()
                    // const y3 = y1 - offsetY
                    const y3 = Decimal.sub(y1, offsetY).toNumber()
                    // 背景中心点的坐标 x2、y2（canvas坐标系）
                    // const left = x3 + img.width * imgScale / 2
                    const left = Decimal.add(x3, Decimal.div(Decimal.mul(img.width, imgScale), 2)).toNumber()
                    // const top = y3 + img.height * imgScale / 2
                    const top = Decimal.add(y3, Decimal.div(Decimal.mul(img.height, imgScale), 2)).toNumber()

                    img.scale(imgScale).set({ left, top })

                    // const scale = fabricCanvas.width / img.width
                    const scale = Decimal.div(fabricCanvas.width, img.width).toNumber()
                    const bgScale = Decimal.div(imgScale, scale).toNumber()
                    setBgScale(bgScale)
                } else {
                    fabricCanvas.centerObject(img)
                }
                checkBgImgBounding(img)
                getBgParams()
                img.on('modified', ev => { // 背景时触发
                    checkBgImgBounding(img)
                    getBgParams()
                })
            })
        } else {
            const bgObjs = fabricCanvas.getObjects().filter(item => item.get('is_background'))
            fabricCanvas.remove(...bgObjs)
        }
    }, [fabricCanvas, bgImage])

    // 背景缩放
    const [bgScale, setBgScale] = useState(1)
    // 更换背景时，bgScale重置为1
    useEffect(() => {
        setBgScale(1)
    }, [bgImage])
    
    // 及时更新canvasSize和canvasClipSize，用来检擦背景边界
    const canvasSizeRef = useRef(canvasSize)
    const canvasClipSizeRef = useRef(canvasClipSize)
    useEffect(() => {
        canvasSizeRef.current = canvasSize
        canvasClipSizeRef.current = canvasClipSize
    }, [canvasSize, canvasClipSize])
    // 背景跟随bgScale缩放
    useEffect(() => {
        if (!fabricCanvas) return
        const bgObj = fabricCanvas.getObjects().find(item => item.get('is_background'))
        if (!bgObj) return
        // const scale = fabricCanvas.width / bgObj.width
        const scale = Decimal.div(canvasSize.width, bgObj.width).toNumber()
        const _bgScale = Decimal.mul(scale, bgScale).toNumber()
        bgObj.scale(_bgScale)
        fabricCanvas.renderAll()
        checkBgImgBounding(bgObj)
        getBgParams()
    }, [fabricCanvas, bgScale, canvasSize, canvasClipSize])
    
    // 检查背景边界，不能在背景抠图区域出现透明
    const checkBgImgBounding = (img) => {
        const canvasSize = canvasSizeRef.current
        const canvasClipSize = canvasClipSizeRef.current
        const canvasSizeWidth = new Decimal(canvasSize.width)
        const canvasSizeHeight = new Decimal(canvasSize.height)
        const canvasClipSizeWidth = new Decimal(canvasClipSize.width)
        const canvasClipSizeHeight = new Decimal(canvasClipSize.height)
        // const left = canvasSize.width > canvasClipSize.width ? (canvasSize.width - canvasClipSize.width) / 2 : 0
        const left = canvasSizeWidth.greaterThan(canvasClipSizeWidth) ? Decimal.div(Decimal.sub(canvasSizeWidth, canvasClipSizeWidth), 2).toNumber() : 0
        // const top = canvasSize.height > canvasClipSize.height ? (canvasSize.height - canvasClipSize.height) / 2 : 0
        const top = canvasSizeHeight.greaterThan(canvasClipSizeHeight) ? Decimal.div(Decimal.sub(canvasSizeHeight, canvasClipSizeHeight), 2).toNumber() : 0

        const bounding = {
            left: left,
            top: top,
            // right: left + canvasClipSize.width,
            right: Decimal.add(left, canvasClipSize.width),
            // bottom: top + canvasClipSize.height,
            bottom: Decimal.add(top, canvasClipSize.height),
            width: canvasClipSize.width,
            height: canvasClipSize.height
        }
        const imgRealWidth = img.getScaledWidth()
        const halfImgRealWidth = Decimal.div(imgRealWidth, 2)
        const imgRealHeight = img.getScaledHeight()
        const halfImgRealHeight = Decimal.div(imgRealHeight, 2)
        const imgRealLeft = Decimal.sub(img.left, halfImgRealWidth)
        const imgRealTop = Decimal.sub(img.top, halfImgRealHeight)
        const imgRealRight = Decimal.add(img.left, halfImgRealWidth)
        const imgRealBottom = Decimal.add(img.top, halfImgRealHeight)
        let imgMusLeft = img.left
        let imgMusTop = img.top
        let boundingLeft = new Decimal(bounding.left)
        let boundingTop = new Decimal(bounding.top)
        let boundingRight = new Decimal(bounding.right)
        let boundingBottom = new Decimal(bounding.bottom)
        if (imgRealLeft.greaterThan(boundingLeft)) {
            // imgMusLeft = bounding.left + imgRealWidth / 2
            imgMusLeft = boundingLeft.add(halfImgRealWidth).toNumber()
        }
        if (imgRealTop.greaterThan(boundingTop)) {
            // imgMusTop = bounding.top + imgRealHeight / 2
            imgMusTop = boundingTop.add(halfImgRealHeight).toNumber()
        }
        if (imgRealRight.lessThan(boundingRight)) {
            // imgMusLeft = (bounding.right - imgRealWidth) + imgRealWidth / 2
            imgMusLeft = boundingRight.sub(imgRealWidth).add(halfImgRealWidth).toNumber()
        }
        if (imgRealBottom.lessThan(boundingBottom)) {
            // imgMusTop = (bounding.top + bounding.height - imgRealHeight) + imgRealHeight / 2
            imgMusTop = boundingTop.add(bounding.height).sub(imgRealHeight).add(halfImgRealHeight).toNumber()
        }
        img.set({ left: imgMusLeft, top: imgMusTop })
        img.setCoords()
        fabricCanvas.renderAll()
    }
    // 获得coord_drag参数
    const getBgParams = async () => {
        const img = fabricCanvas.getObjects().find(item => item.get('is_background'))
        if (img) {
            const canvasSize = canvasSizeRef.current
            const canvasClipSize = canvasClipSizeRef.current
            const canvasSizeWidth = new Decimal(canvasSize.width)
            const canvasSizeHeight = new Decimal(canvasSize.height)
            const canvasClipSizeWidth = new Decimal(canvasClipSize.width)
            const canvasClipSizeHeight = new Decimal(canvasClipSize.height)
            // 高亮区域坐标 x1、y1（canvas坐标系）
            // const x1 = canvasSize.width > canvasClipSize.width ? (canvasSize.width - canvasClipSize.width) / 2 : 0
            const x1 = canvasSizeWidth.greaterThan(canvasClipSizeWidth) ? Decimal.div(Decimal.sub(canvasSizeWidth, canvasClipSizeWidth), 2).toNumber() : 0
            // const y1 = canvasSize.height > canvasClipSize.height ? (canvasSize.height - canvasClipSize.height) / 2 : 0
            const y1 = canvasSizeHeight.greaterThan(canvasClipSizeHeight) ? Decimal.div(Decimal.sub(canvasSizeHeight, canvasClipSizeHeight), 2).toNumber() : 0            
            // 背景中心点的坐标 x2、y2（canvas坐标系）
            const x2 = img.left
            const y2 = img.top
            // 背景左上角的坐标x3、y3（canvas坐标系）
            // const x3 = x2 - img.getScaledWidth() / 2
            const x3 = Decimal.sub(x2, Decimal.div(img.getScaledWidth(), 2)).toNumber()
            // const y3 = y2 - img.getScaledHeight() / 2
            const y3 = Decimal.sub(y2, Decimal.div(img.getScaledHeight(), 2)).toNumber()

            // 计算出在原图坐标系下裁图区域的左上角坐标
            // const offsetX = x1 - x3
            const offsetX = Decimal.sub(x1, x3).toNumber()
            // const offsetY = y1 - y3
            const offsetY = Decimal.sub(y1, y3).toNumber()
            // const finalX = offsetX / img.scaleX
            const finalX = Decimal.div(offsetX, img.scaleX).toNumber()
            // const finalY = offsetY / img.scaleX
            const finalY = Decimal.div(offsetY, img.scaleX).toNumber()

            // 计算出在原图坐标系下裁图区域的宽高
            // const finalW = canvasClipSize.width / img.scaleX
            const finalW = Decimal.div(canvasClipSize.width, img.scaleX).toNumber()
            // const finalH = canvasClipSize.height / img.scaleX
            const finalH = Decimal.div(canvasClipSize.height, img.scaleX).toNumber()

            coordDragRef.current = {
                lt_x: toFixed(finalX),
                lt_y: toFixed(finalY),
                width: toFixed(finalW),
                height: toFixed(finalH)
            }
            setCoordDrag(coordDragRef.current)
        }
    }
 
    // 仅用来绘制遮罩
    useEffect(() => {
        if (!fabricCanvas) return
        const maskObj = fabricCanvas.getObjects().find(item => item.get('is_mask'))
        if (maskObj) fabricCanvas.remove(maskObj)
        if (!bgImage) return // 背景不存在，即预设风格模式下，canvasSize与canvasClipSize一致，不需要加载mask
        if ((canvasSize.width != canvasClipSize.width) || (canvasSize.height != canvasClipSize.height)) {
            const rect = new fabric.Rect({
                width: canvasSize.width,
                height: canvasSize.height,
                originX: 'center',
                originY: 'center',
                fill: 'black',
                opacity: 0.5,
                selectable: false,
                evented: false,
                lockMovementX: true,
                lockMovementY: true,
                perPixelTargetFind: true,
                hasBorders: false,
                hasControls: false,
            })
            const canvasSizeWidth = new Decimal(canvasSize.width)
            const canvasSizeHeight = new Decimal(canvasSize.height)
            const canvasClipSizeWidth = new Decimal(canvasClipSize.width)
            const canvasClipSizeHeight = new Decimal(canvasClipSize.height)
            // 高亮区域坐标 x1、y1（canvas坐标系）
            // const x1 = canvasSize.width > canvasClipSize.width ? (canvasSize.width - canvasClipSize.width) / 2 : 0
            const x1 = canvasSizeWidth.greaterThan(canvasClipSizeWidth) ? Decimal.div(Decimal.sub(canvasSizeWidth, canvasClipSizeWidth), 2).toNumber() : 0
            // const y1 = canvasSize.height > canvasClipSize.height ? (canvasSize.height - canvasClipSize.height) / 2 : 0
            const y1 = canvasSizeHeight.greaterThan(canvasClipSizeHeight) ? Decimal.div(Decimal.sub(canvasSizeHeight, canvasClipSizeHeight), 2).toNumber() : 0   


            // const width = canvasSize.width > canvasClipSize.width ? (canvasSize.width - canvasClipSize.width) / 2 : canvasSize.width
            const width = canvasSizeWidth.greaterThan(canvasClipSizeWidth) ? Decimal.div(Decimal.sub(canvasSizeWidth, canvasClipSizeWidth), 2).toNumber() : canvasSizeWidth.toNumber()
            // const height = canvasSize.height > canvasClipSize.height ? (canvasSize.height - canvasClipSize.height) / 2 : canvasSize.height
            const height = canvasSizeHeight.greaterThan(canvasClipSizeHeight) ? Decimal.div(Decimal.sub(canvasSizeHeight, canvasClipSizeHeight), 2).toNumber() : canvasSizeHeight.toNumber()
            // const top = canvasSize.height > canvasClipSize.height ? (canvasSize.height - canvasClipSize.height) / 2 + canvasClipSize.height : 0
            const top = canvasSizeHeight.greaterThan(canvasClipSizeHeight) ? Decimal.add(Decimal.div(Decimal.sub(canvasSizeHeight, canvasClipSizeHeight), 2), canvasClipSizeHeight).toNumber() : 0
            // const left = canvasSize.width > canvasClipSize.width ? (canvasSize.width - canvasClipSize.width) / 2 + canvasClipSize.width : 0
            const left = canvasSizeWidth.greaterThan(canvasClipSizeWidth) ? Decimal.add(Decimal.div(Decimal.sub(canvasSizeWidth, canvasClipSizeWidth), 2), canvasClipSizeWidth).toNumber() : 0
            const clipPath = new fabric.Group([
                new fabric.Rect({
                    width: toFixed(width),
                    height: toFixed(height),
                    left: 0,
                    top: 0,
                    fill: 'black',
                }),
                new fabric.Rect({
                    width: toFixed(width),
                    height: toFixed(height),
                    left: toFixed(left),
                    top: toFixed(top),
                    fill: 'black',
                }),
            ], {
                originX: 'center',
                originY: 'center',
                left: 0,
                top: 0
            })
            rect.clipPath = clipPath
            rect.set('is_mask', true)
            fabricCanvas.add(rect)
            fabricCanvas.centerObject(rect)
            fabricCanvas.moveTo(rect, 3)
        }
    }, [fabricCanvas, canvasSize, canvasClipSize, bgImage])

    // 绘制模特
    const trimmedImgSize = useRef()
    // 检查模特边界
    const checkModelImgBounding = (img) => {
        const canvasSize = canvasSizeRef.current
        const canvasClipSize = canvasClipSizeRef.current
        const canvasSizeWidth = new Decimal(canvasSize.width)
        const canvasSizeHeight = new Decimal(canvasSize.height)
        const canvasClipSizeWidth = new Decimal(canvasClipSize.width)
        const canvasClipSizeHeight = new Decimal(canvasClipSize.height)
        // const left = canvasSize.width > canvasClipSize.width ? (canvasSize.width - canvasClipSize.width) / 2 : 0
        const left = canvasSizeWidth.greaterThan(canvasClipSizeWidth) ? Decimal.div(Decimal.sub(canvasSizeWidth, canvasClipSizeWidth), 2).toNumber() : 0
        // const top = canvasSize.height > canvasClipSize.height ? (canvasSize.height - canvasClipSize.height) / 2 : 0
        const top = canvasSizeHeight.greaterThan(canvasClipSizeHeight) ? Decimal.div(Decimal.sub(canvasSizeHeight, canvasClipSizeHeight), 2).toNumber() : 0 
        const bounding = {
            left: left,
            top: top,
            right: Decimal.add(left, canvasClipSize.width).toNumber(),
            bottom: Decimal.add(top, canvasClipSize.height).toNumber(),
            width: canvasClipSize.width,
            height: canvasClipSize.height
        }
        let objBoundingRect = img.getBoundingRect()
        let objBoundingLeft = new Decimal(objBoundingRect.left)
        let objBoundingTop = new Decimal(objBoundingRect.top)
        let objBoundingWidth = new Decimal(objBoundingRect.width)
        let objBoundingHeight = new Decimal(objBoundingRect.height)
        let boundingLeft = new Decimal(bounding.left)
        let boundingRight = new Decimal(bounding.right)
        let boundingTop = new Decimal(bounding.top)
        let boundingBottom = new Decimal(bounding.bottom)
        if (objBoundingLeft.greaterThan(boundingRight.sub(10))) {
            // img.left = bounding.right - objBoundingRect.width
            img.left = boundingRight.sub(objBoundingWidth).toNumber()
        }
        if (objBoundingLeft.add(objBoundingWidth).lessThan(boundingLeft.add(10))) {
            // img.left = bounding.left
            img.left = boundingLeft.toNumber()
        }
        if (objBoundingTop.greaterThan(boundingBottom.sub(10))) {
            // img.top = bounding.bottom - objBoundingRect.height
            img.top = boundingBottom.sub(objBoundingHeight).toNumber()
        }
        if (objBoundingTop.add(objBoundingHeight).lessThan(boundingTop.add(10))) {
            // img.top = bounding.top
            img.top = boundingTop.toNumber()
        }
        img.setCoords()
        fabricCanvasRef.current.renderAll()
    }
    // 获得模特位置参数
    const getModelImgParams = (img) => {
        const canvasWidth = canvasSizeRef.current.width
        const canvasHeight = canvasSizeRef.current.height
        const canvasClipWidth = canvasClipSizeRef.current.width
        const canvasClipHeight = canvasClipSizeRef.current.height
        // const scaleX = canvasClipWidth / imgSize.width
        const scaleX = Decimal.div(canvasClipWidth, imgSize.width).toNumber()
        // const scaleY = canvasClipHeight / imgSize.height
        const scaleY = Decimal.div(canvasClipHeight, imgSize.height).toNumber()
        const minScale = Decimal.min(scaleX, scaleY)
        
        // 计算图片缩放
        const imgRealScale = Decimal.div(img.scaleX, minScale).toNumber()
        scaleRateRef.current = imgRealScale
        setScaleRate(scaleRateRef.current)

        // 计算模特位移量
        const imgRealScaleX = Decimal.div(img.scaleX, scaleX).toNumber()
        const imgRealScaleY = Decimal.div(img.scaleX, scaleY).toNumber()
        // const trimmedImgRealX = (img.left - (canvasWidth - canvasClipWidth) / 2) / scaleX
        const trimmedImgRealX = Decimal.div((Decimal.sub(img.left, Decimal.div(Decimal.sub(canvasWidth, canvasClipWidth), 2))), scaleX).toNumber()
        // const trimmedImgRealY = (img.top - (canvasHeight - canvasClipHeight) / 2) / scaleY
        const trimmedImgRealY = Decimal.div((Decimal.sub(img.top, Decimal.div(Decimal.sub(canvasHeight, canvasClipHeight), 2))), scaleY).toNumber()
        // console.log('trimmedImgRealX', Math.floor(trimmedImgRealX), Math.floor(trimmedImgRealY)) // right
        // const imgRealX = trimmedImgRealX - (trimmedImgSize.current.x * imgRealScaleX)
        const imgRealX = Decimal.sub(trimmedImgRealX, Decimal.mul(trimmedImgSize.current.x, imgRealScaleX)).toNumber()
        // const imgRealY = trimmedImgRealY - (trimmedImgSize.current.y * imgRealScaleY)
        const imgRealY = Decimal.sub(trimmedImgRealY, Decimal.mul(trimmedImgSize.current.y, imgRealScaleY)).toNumber()
        // console.log('imgRealX', Math.floor(imgRealX), Math.floor(imgRealY))
        ltXRef.current = imgRealX
        setLtX(ltXRef.current)
        ltYRef.current = imgRealY
        setLtY(ltYRef.current)
    }

    return (
        <div style={{width: '100%', height: '100%', background: `url(${patternImageBase64}) repeat`}}>
            <Spin spinning={loading} size="large">
                <div style={{position: 'relative'}}>
                    <canvas ref={canvasRef}></canvas>
                    {
                        bgImage &&
                        <ZoomBg value={bgScale} onChange={setBgScale} />
                    }
                </div>
                
            </Spin>
        </div>
    )
}

export default Drag