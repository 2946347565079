import React, {useState, useEffect, useRef, useContext, useMemo} from 'react';
import styles from './modelLeftIndex.module.scss'
import ModelSelection from './ModelSelection';
import RatioSelection from './RatioSelection'
import Drawer from './drawer/drawer'
// import SelectScene from './modelAndcommodityScene/selectScene/selectScene'
import { Tag, Button, Dropdown, notification, Checkbox, message, Badge } from 'antd';
import { IMAGE_TYPE, CLOTH_TYPE } from "@utils/CONST"
import ResolutionSelection from '@view/compoents/ResolutionSelection/ResolutionSelection';
// import SoftEdge from './modelAndcommodityScene/softEdge/softEdge'
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import SoftEdgeSelection from './SoftEdgeSelection'
import { GenerateContext } from "@utils/GenerateContext"
import TextTag from '@view/compoents/textTag/textTag'
import StrengthSlider from './CustomScene/components/StrengthSlider'
import { AppContext } from '@utils/AppContext';
import PoseSelection from './PoseSelection';
import SceneSelection from './SceneSelection';
import FeatureSelection from './FeatureSelection';
import SceneSwitchTab from './SceneSwitchTab';
import CustomScene from './CustomScene';
import eventBus from '@utils/eventBus';
import API from '@api/api'
import * as _ from "underscore";

const ModelLeftIndex = (props) => {
    const {isProEnv} = useContext(AppContext)
    const {    
        initialized, // taskcache初始化完成
        imageType, // model/dressform/clothes...
        dragScaleRef, dragScale, setDragScale, // 商品+softedge布局等
        bgImage,
        setBgImage,
        segmentResult, setSegmentResult, // 分割结果列表-套装
        segmentResult1, setSegmentResult1, // 分割结果列表-上身
        segmentResult2, setSegmentResult2, // 分割结果列表-下身
        modelStyle, setModelStyle, // 选择的模特
        poseStyleList, setPoseStyleList, // 选择的姿势列表
        customPoseStyleList, setCustomPoseStyleList, // 选择的自定义姿势列表
        bgStyle, setBgStyle, // 选择的场景
        createNum, setCreateNum, // 生成数量
        clothType, setClothType, // 来自服装类型的选择，仅用于衣服图的区分
        subBgId, setSubBgId, // 场景内部子index，目前只能为-1
        // 商品自定义尺寸
        imgWidthRef,
        imgWidth, setImgWidth,
        imgHeightRef, 
        imgHeight, setImgHeight,
        imgRatioRef, 
        imgRatio, setImgRatio,
        // 自定义场景字段
        definedFlag, setDefinedFlag,
        positivePrompt, setPositivePrompt,
        refImg, setRefImg,
        refImgMask, setRefImgMask,
        refVersion, setRefVersion,
        refStrength, setRefStrength,
        setRefImgLocalInfo,
        // 清除taskCache
        disableCacheRef,
        clothLoadingFlag, clothLoadingFlag2,
        isSceneFracturing, setIsSceneFracturing, // 场景裂变
        setDpScale, // 场景裂变强度
        keepPose, // 保持模特
        keepBkg, // 保持背景
        maskMoveable,
        isCustomScene,
        setIsCustomScene,
    } = useContext(GenerateContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerBackgroundType, setDrawerBackgroundType] = useState(); // 预设风格/预设参考图
    const [drawerPoseIsCustom, setDrawerPoseIsCustom] = useState(0) // 自定义姿势
    const [modelOrScene, setModelOrScene] = useState(); // Model/Pose/SoftEdge/Scene
    const [refreshRecent, setRefreshRecent] = useState(); // 提交生成后强制刷新资源
    const drawerRef = useRef();
    const isDrawerOpenRef = useRef(false);
    
    // 图像尺寸
    const resolutionRef = useRef()
    const [resolutionDisabled, setResolutionDisabled] = useState(true)
    const [refImgSize, setRefImgSize] = useState() // 自定义场景参考图尺寸

    // 内部字段&函数
    const notFoundModelNoticed = useRef(false)
    const notFoundSceneNoticed = useRef(false)
    const notFoundPoseNoticed = useRef(false)

    // 模特/姿势更改
    const onModelChange = (modelStyle) => {
        setModelStyle(modelStyle)
    }
    // 姿势更改
    const onPoseChange = (poseStyle, isSelfDefined=false, selfDefinedImg='') => {
        let list = [...poseStyleList]
        if (poseStyleList.find(item => item.name == poseStyle)) {
            list = poseStyleList.filter(item => item.name != poseStyle)
        } else if ([...poseStyleList, ...customPoseStyleList].length < 5) {
            list = [...poseStyleList, {
                name: poseStyle,
                isSelfDefined: isSelfDefined,
                selfDefinedImg: selfDefinedImg,
            }]
        } else {
            message.warning('最多选择5个模特')
        }
        setPoseStyleList([...list])
    }
    const onCustomPoseChange = (poseStyle, isSelfDefined=false, selfDefinedImg='') => {
        let list = [...customPoseStyleList]
        if (customPoseStyleList.find(item => item.name == poseStyle)) {
            list = customPoseStyleList.filter(item => item.name != poseStyle)
        } else if ([...poseStyleList, ...customPoseStyleList].length < 5) {
            list = [...customPoseStyleList, {
                name: poseStyle,
                isSelfDefined: isSelfDefined,
                selfDefinedImg: selfDefinedImg,
            }]
        } else {
            message.warning('最多选择5个模特')
        }
        setCustomPoseStyleList([...list])
    }
    // 场景更改
    const onSceneChange = (bgStyle) => {
        setBgStyle(bgStyle)
    }
    // 生成图片数更改
    const onCreateNumChange = (val) => {
        if(val == 'add') {
            if(createNum < 4) setCreateNum(pre => pre + 1)
        } else if(val == 'reduce') {
            if(createNum > 1) setCreateNum(pre => pre - 1)
        }
    }
    // 自定义场景更改
    const onCustomChange = ({
        definedFlag,
        positivePrompt,
        refImg,
        refImgMask,
        refImgProcessed,
        refImgLocalInfo,
        refVersion,
        refStrength,
    }) => {
        // 场景切换
        setDefinedFlag(definedFlag)
        setPositivePrompt(positivePrompt)
        setRefImg(refImg)
        setRefImgMask(refImgMask)
        setRefVersion(refVersion)
        setRefStrength(refStrength)
        setRefImgLocalInfo(refImgLocalInfo)
        if (imageType == IMAGE_TYPE.COMMODITY) {
            setBgImage((refVersion == 1 && refImg) || (refVersion == 3 && refImgProcessed))
        } else if (imageType == IMAGE_TYPE.MODEL) {
            if (definedFlag == 0) { // 预设场景，非场景裂变
                setIsSceneFracturing(false)
            } else {
                if (refVersion == 1) setIsSceneFracturing(false) // 相似风格，非场景裂变
                else { // 场景裂变
                    setIsSceneFracturing(true)
                    setDpScale(refStrength)
                }
            }
        } 
    }
    // 图像比例
    const onCanvasChange = (w,h,ratio) => {
        imgWidthRef.current = w
        setImgWidth(w)
        imgHeightRef.current = h
        setImgHeight(h)
        imgRatioRef.current = ratio
        setImgRatio(ratio)
    }
    const customChangeFunc = (params) => {
        setRefImgSize(params.refImgSize)
        onCustomChange(params)
    }
    useEffect(() => {
        if (imageType != IMAGE_TYPE.COMMODITY) return // 仅商品图逻辑
        if (refVersion == 1 || refVersion == 3) { // v1-需要将参考图加载到画布上，需要锁定尺寸
            if (!refImgSize) {
                setResolutionDisabled(false)
                return
            } else {
                if (resolutionRef.current) resolutionRef.current.customSize(refImgSize.width, refImgSize.height)
                setResolutionDisabled(true)
            }
        } else { // v2-不需要将参考图加载到画布上，不需要锁定尺寸
            setResolutionDisabled(false)
        }
    }, [imageType, refImgSize, refVersion])

    const foundPoseFunc = () => notFoundPoseNoticed.current = true // 找到了姿势，修改标记
    const notFoundPoseFunc = () => {
        if (notFoundPoseNoticed.current) return
        notFoundPoseNoticed.current = true
        if (!location.state?.rebuild) return
        notification.info({
            message: `提醒`,
            description: `原模特组已更新，请手动选择模特`,
            placement: 'bottomRight',
        })
    } // 没有找到模特，需要弹窗提醒

    const foundModelFunc = () => notFoundModelNoticed.current = true // 找到了模特，修改标记
    const notFoundModelFunc = () => {
        if (notFoundModelNoticed.current) return
        notFoundModelNoticed.current = true
        if (!location.state?.rebuild) return
        notification.info({
            message: `提醒`,
            description: `原模特组已更新，请手动选择模特`,
            placement: 'bottomRight',
        })
    } // 没有找到模特，需要弹窗提醒

    const foundSceneFunc = () => notFoundSceneNoticed.current = true // 找到了场景，修改标记
    const notFoundSceneFunc = () => {
        if (notFoundSceneNoticed.current) return
        notFoundSceneNoticed.current = true
        if (!location.state?.rebuild) return
        notification.info({
            message: `提醒`,
            description: `原场景组已更新，请手动选择场景`,
            placement: 'bottomRight',
        })
    } // 没有找到场景，需要弹窗提醒

    const maskIsReady = useMemo(() => {
        const segs = clothType == CLOTH_TYPE.SUIT ? 
            segmentResult1.concat(segmentResult2) : 
            segmentResult            
        return segs && segs.length > 0
    }, [segmentResult, segmentResult1, segmentResult2, clothType])

    // 生成按钮
    const createHandle = async () => {
        if(clothLoadingFlag || clothLoadingFlag2 || !maskIsReady) return
        eventBus.emit('TOUR_NEXT')
        setIsLoading(true)
        try {
            await props.onCreate()
            setRefreshRecent({})
        } catch (err) {
            console.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    // Drawer关闭
    const drawerTriggerDebounce = _.debounce((value) => {
        isDrawerOpenRef.current = value
        setIsDrawerOpen(value);
    }, 100)
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event) => {
        if (drawerRef.current && 
            !drawerRef.current.contains(event.target) && 
            !event.target.classList.contains('dropdown') && 
            !event.target.closest('.ModelFilterSelect') && 
            !event.target.closest('.ant-select-item') &&
            !event.target.closest('.more_btn')) {
            if(isDrawerOpenRef.current) {
                console.log('setIsDrawerOpen', false)
                drawerTriggerDebounce(false)
            }
        }
    };
    const toggleDrawer = (e, type) => {
        e && e.stopPropagation()
        if (type == modelOrScene) {
            if(!isDrawerOpenRef.current) {
                drawerTriggerDebounce(true)
            } else {
                drawerTriggerDebounce(false)
            }
        } else {
            if(!isDrawerOpenRef.current) drawerTriggerDebounce(true)
        }
        setModelOrScene(type)
    }

    // 保持原图场景/场景裂变，不可修改尺寸
    const selectRatioDisabled = useMemo(() => {
        return !maskMoveable
    }, [maskMoveable])

    const RightContent = (
        <CustomScene
            definedFlag={definedFlag}
            positivePrompt={positivePrompt}
            bkgUrl={refImg}
            sceneType={imageType}
            refVersion={refVersion}
            refStrength={refStrength}
            onChange={customChangeFunc}
        />
    )
    const ModelTitle = ({title="选择模特"}) => (
        <div className={styles.Title}>
            <span>{title}</span>
            <span onClick={e => toggleDrawer(e, 'Model')}>{(isDrawerOpen && modelOrScene == 'Model') ? <>收起&nbsp;&nbsp;<DoubleLeftOutlined /></> : <>更多&nbsp;&nbsp;<DoubleRightOutlined /></>}</span>
        </div>
    )
    const PoseTitle = ({title="选择模特", toggleDrawer}) => (
        <div className={styles.Title}>
            <span>{title}</span>
            <span onClick={e => toggleDrawer(e, 'Pose')}>{(isDrawerOpen && modelOrScene == 'Pose') ? <>收起&nbsp;&nbsp;<DoubleLeftOutlined /></> : <>更多&nbsp;&nbsp;<DoubleRightOutlined /></>}</span>
        </div>
    )
    const SceneTitle = ({title="选择场景", toggleDrawer, toggleDrawerDisabled}) => (
        <div className={styles.Title}>
            <span>{title}</span>
            {!toggleDrawerDisabled && <span onClick={e => toggleDrawer(e, 'Scene')}>{(isDrawerOpen && modelOrScene == 'Scene') ? <>收起&nbsp;&nbsp;<DoubleLeftOutlined /></> : <>更多&nbsp;&nbsp;<DoubleRightOutlined /></>}</span>}
        </div>
    )
    const SoftEdgeTitle = ({title="添加元素"}) => (
        <div className={styles.Title}>
            <span className={styles.unnecessary}>{title}</span>
            <span onClick={e => toggleDrawer(e, 'Softedge')}>{(isDrawerOpen && modelOrScene == 'Softedge') ? <>收起&nbsp;&nbsp;<DoubleLeftOutlined /></> : <>更多&nbsp;&nbsp;<DoubleRightOutlined /></>}</span>
        </div>
    )

    const [tryonPoseTabKey, setTryonPoseTabKey] = useState(0)
    const handleTryonPoseTabChange = (key) => {
        setTryonPoseTabKey(key)
    }
    useEffect(() => {
        setTimeout(() => {
            if (tryonPoseTabKey == 0 && poseStyleList.length > 0) {
                toggleDrawer(null, 'Pose')
                setDrawerPoseIsCustom(tryonPoseTabKey)
            } else if (tryonPoseTabKey == 1 && customPoseStyleList.length > 0) {
                toggleDrawer(null, 'Pose')
                setDrawerPoseIsCustom(tryonPoseTabKey)
            }   
        })
    }, [tryonPoseTabKey])
    const tryonPoseTabItems = [{
        key: 0,
        label: (
            <Badge count={poseStyleList.length} color={[...poseStyleList, ...customPoseStyleList].length < 5 ? 'red' : '#CBCBCB'} size="small" offset={[10, 7]}>
                <span>预设模特</span>
            </Badge>
        ),
        children: <PoseSelection
            key={0}
            visibleCount={6}
            poseNameList={poseStyleList.map(item => item.name)}
            onPoseChange={onPoseChange}
            notFoundPoseFunc={notFoundPoseFunc}
            foundPoseFunc={foundPoseFunc}
            refreshRecent={refreshRecent}
        />
    }, {
        key: 1,
        label: (
            <Badge count={customPoseStyleList.length} color={[...poseStyleList, ...customPoseStyleList].length < 5 ? 'red' : '#CBCBCB'} size="small" offset={[10, 7]}>
                <span>自定义模特</span>
            </Badge>
        ),
        limitedFree: true,
        children: <PoseSelection
            key={1}
            isCustomPose
            visibleCount={6}
            poseNameList={customPoseStyleList.map(item => item.name)}
            onPoseChange={onCustomPoseChange}
            notFoundPoseFunc={notFoundPoseFunc}
            foundPoseFunc={foundPoseFunc}
            refreshRecent={refreshRecent}
        />
    }]

    const [tryonSceneTabKey, setTryonSceneTabKey] = useState(isCustomScene ? 2 : 1)
    useEffect(() => {
        if (isCustomScene) setTryonSceneTabKey(2)
    }, [isCustomScene])
    const handleTryonSceneTabChange = (key) => {
        setTryonSceneTabKey(key)
        if (key == 2) {
            setIsCustomScene(true)
            setBgImage()
        } else {
            setIsCustomScene(false)
        }
    }
    const tryonSceneTabItems = [{
        key: 1,
        label: '预设场景',
        children: <SceneSelection
            key={"tryon"}
            sceneName={bgStyle}
            visibleCount={6}
            notFoundSceneFunc={notFoundSceneFunc}
            foundSceneFunc={foundSceneFunc}
            onSceneChange={onSceneChange}
            refreshRecent={refreshRecent}
        />
    }, {
        key: 2,
        label: '自定义场景',
        children: RightContent,
        disabled: true,
    }]
    const TryonRender = (
        <>
        <div id="tour_target_pose">
        <PoseTitle title="选择模特"  toggleDrawer={(...args) => {toggleDrawer(...args);setDrawerPoseIsCustom(tryonPoseTabKey)}} />
        <SceneSwitchTab
            activeKey={tryonPoseTabKey}
            items={tryonPoseTabItems}
            onChange={handleTryonPoseTabChange}
        />
        </div>
        <div id="tour_target_model">
        <ModelTitle title="更换容貌" />
        <ModelSelection
            key={"tryon"}
            visibleCount={6}
            modelName={modelStyle}
            onModelChange={onModelChange}
            notFoundModelFunc={notFoundModelFunc}
            foundModelFunc={foundModelFunc}
            refreshRecent={refreshRecent}
        />
        </div>
        <SceneTitle title="更换场景" toggleDrawerDisabled={tryonSceneTabKey==2} toggleDrawer={toggleDrawer} />
        <div id="tour_target_scene">
        <SceneSwitchTab
            activeKey={tryonSceneTabKey}
            items={tryonSceneTabItems}
            onChange={handleTryonSceneTabChange}
        />
        </div>
        </>
    )

    const [dressformSceneTabKey, setDressformSceneTabKey] = useState(isCustomScene ? 2 : 1)
    useEffect(() => {
        if (isCustomScene) setDressformSceneTabKey(2)
    }, [isCustomScene])
    const handleDressformSceneTabChange = (key) => {
        setDressformSceneTabKey(key)
        if (key == 2) {
            setIsCustomScene(true)
            setBgImage()
        } else {
            setIsCustomScene(false)
        }
    }
    const dressformSceneTabItems = [{
        key: 1,
        label: '预设场景',
        children: <SceneSelection
            key={"dressform"}
            sceneName={bgStyle}
            visibleCount={6}
            notFoundSceneFunc={notFoundSceneFunc}
            foundSceneFunc={foundSceneFunc}
            onSceneChange={onSceneChange}
            refreshRecent={refreshRecent}
        />
    }, {
        key: 2,
        label: '自定义场景',
        children: RightContent,
        disabled: true
    }]
    const DressformRender = (
        <>
        <div id="tour_target_model">
        <ModelTitle />
        <ModelSelection
            key={"dressform"}
            visibleCount={9}
            modelName={modelStyle}
            onModelChange={onModelChange}
            notFoundModelFunc={notFoundModelFunc}
            foundModelFunc={foundModelFunc}
            refreshRecent={refreshRecent}
        />
        </div>
        <SceneTitle toggleDrawerDisabled={dressformSceneTabKey==2} toggleDrawer={toggleDrawer} />
        <div id="tour_target_scene">
        <SceneSwitchTab
            activeKey={dressformSceneTabKey}
            items={dressformSceneTabItems}
            onChange={handleDressformSceneTabChange}
        />
        </div>
        </>
    )

    // 根据bgStyle判断是预设风格还是参考图，重置modelSceneTabKey，仅用于场景回显
    const initedRef = useRef(false)
    useEffect(() => {
        if (imageType != IMAGE_TYPE.MODEL) return
        if (!bgStyle) return
        if (initedRef.current) return
        initedRef.current = true
        if (isCustomScene) return   
        if (['00_random','00_keep'].includes(bgStyle)) {
            setModelSceneTabKey(0)
        } else {
            API.modelBackgroundList({ imageType: imageType }).then(res => {
                if (res.code != 0) throw new Error(res.message)
                const list = res.data
                    .filter(item => item.name != '00_random' && item.name != '00_keep')
                    .map(item => {
                        if (item.attr.backgroundType == 0) return item
                        else { // 因为窗幔布艺目前属于参考图，需要过滤掉随机icon
                            if (/drapery_background/.test(item.name)) {
                                return {
                                    ...item,
                                    subList: item.subList.filter(item => item.name !== '00_drapery_background_random')
                                }
                            } else {
                                return item
                            }
                        }                   
                    })
                let found
                for (let i = 0; i < list.length; i++) {
                    if (list[i].name === bgStyle) {
                        found = list[i];
                        break;
                    }
                    let subList = list[i].subList
                    if (subList) {
                        for (let j = 0; j < subList.length; j++) {
                            if (subList[j].name === bgStyle) {
                                found = subList[j];
                                break
                            }
                        }
                    }
                }
                if (found) {
                    console.log('init setModelSceneTabKey', found.attr.backgroundType)
                    setModelSceneTabKey(found.attr.backgroundType)
                }
            }).catch(err => {
                console.error(err.message)
            })
        }
    }, [bgStyle, isCustomScene])

    const [modelSceneTabKey, setModelSceneTabKey] = useState(isCustomScene ? 2 : 0)
    useEffect(() => {
        if (isCustomScene) setModelSceneTabKey(2)
    }, [isCustomScene])
    const handleModelSceneTabChange = (key) => {
        setModelSceneTabKey(key)
        if (key == 2) {
            setIsCustomScene(true)
            setBgImage()
        } else {
            setIsCustomScene(false)
        }
    }
    const modelSceneTabItems = [{
        key: 0,
        label: '预设风格',
        children: <SceneSelection
            key={"model0"}
            sceneName={bgStyle}
            backgroundType={0} // 预设风格
            visibleCount={9}
            notFoundSceneFunc={notFoundSceneFunc}
            foundSceneFunc={foundSceneFunc}
            onSceneChange={onSceneChange}
            refreshRecent={refreshRecent}
        />
    }, {
        key: 1,
        label: '参考图',
        children: <SceneSelection
            key={"model1"}
            sceneName={bgStyle}
            backgroundType={1} // 预设参考图
            visibleCount={9}
            notFoundSceneFunc={notFoundSceneFunc}
            foundSceneFunc={foundSceneFunc}
            onSceneChange={onSceneChange}
            refreshRecent={refreshRecent}
        />
    }, {
        key: 2,
        label: '自定义场景',
        children: RightContent,
        isNew: false
    }]
    const ModelRender = (
        <>
        <RatioSelection
            disabled={selectRatioDisabled}
            bgImage={bgImage}
            value={imgRatio}
            onChange={(ratio) => onCanvasChange(0,0,ratio)}
            onTriggerComposeOpt={props.onTriggerComposeOpt}
        />
        <div id="tour_target_model">
        <ModelTitle />
        <ModelSelection
            key={"model"}
            visibleCount={9}
            modelName={modelStyle}
            onModelChange={onModelChange}
            notFoundModelFunc={notFoundModelFunc}
            foundModelFunc={foundModelFunc}
            refreshRecent={refreshRecent}
        />
        </div>
        <FeatureSelection />
        <SceneTitle toggleDrawerDisabled={modelSceneTabKey==2} toggleDrawer={(...args) => {toggleDrawer(...args);setDrawerBackgroundType(modelSceneTabKey)}} />
        <div id="tour_target_scene">
        <SceneSwitchTab
            activeKey={modelSceneTabKey}
            items={modelSceneTabItems}
            onChange={handleModelSceneTabChange}
        />
        </div>
        </>
    )

    const [commoditySceneTabKey, setCommoditySceneTabKey] = useState(isCustomScene ? 2 : 1)
    useEffect(() => {
        if (isCustomScene) setCommoditySceneTabKey(2)
    }, [isCustomScene])
    const handleCommoditySceneTabChange = (key) => {
        setCommoditySceneTabKey(key)
        if (key == 2) {
            setIsCustomScene(true)
            setBgImage()
        } else {
            setIsCustomScene(false)
        }
    }
    const commoditySceneTabItems = [{
        key: 1,
        label: '预设场景',
        children: <>
            <SceneSelection
                key={"commodity"}
                sceneName={bgStyle}
                visibleCount={9}
                notFoundSceneFunc={notFoundSceneFunc}
                foundSceneFunc={foundSceneFunc}
                onSceneChange={onSceneChange}
                refreshRecent={refreshRecent}
            />
            {
                definedFlag == 0?<>
                <SoftEdgeTitle />
                <SoftEdgeSelection
                    getEdgeUrl={props.onEdgeChange}
                    toggleDrawer={toggleDrawer}
                />
                </>: null
            }
        </>
    }, {
        key: 2,
        label: '自定义场景',
        children: RightContent,
        isNew: false,
    }]
    const CommodityRender = (
        <>
            <div className={styles.Title} style={{marginBottom: 13}}>
                <span>图像尺寸</span>
            </div>
            <div style={{padding: '0 8px'}}>
                <ResolutionSelection
                    ref={resolutionRef}
                    disabled={resolutionDisabled}
                    initImgRatio={imgRatio}
                    initImgWidth={imgWidth}
                    initImgHeight={imgHeight}
                    onChange={onCanvasChange}
                />
            </div>
            <SceneTitle toggleDrawerDisabled={commoditySceneTabKey==2} toggleDrawer={toggleDrawer} />
            <div id="tour_target_scene">
            <SceneSwitchTab
                activeKey={commoditySceneTabKey}
                items={commoditySceneTabItems}
                onChange={handleCommoditySceneTabChange}
            />
            </div>
        </>
    )

    // 虹豆消耗：衣服图40/张；其他10/张
    const consumAmount = useMemo(() => {
        if (imageType == IMAGE_TYPE.CLOTHES) {
            return (createNum * [...poseStyleList, ...customPoseStyleList].length) * 40
        } else {
            return createNum * 10
        }
    }, [imageType, createNum, poseStyleList, customPoseStyleList])

    // isSceneFracturing
    const createBtnDisabled = useMemo(() => {
        let disabled = true
        if (imageType == IMAGE_TYPE.COMMODITY) { // 商品版 自定义场景：refImg或参考图必填一个；预设场景-场景必选
            if (!dragScale || dragScale.length == 0) disabled = true // 商品版dragScale不能为空，必须等画布上加载出商品才能提交
            else {
                if (definedFlag == 1) disabled = (!refImg && !positivePrompt)
                else disabled = !bgStyle
            }
        } else if (imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM) { // 商品版 自定义场景：refImg或参考图必填一个；预设场景-场景/模特必选一项
            if (definedFlag == 1) {
                if (isSceneFracturing) {
                    if (!keepPose) disabled = !modelStyle // 场景裂变时，需要选个模特
                    else disabled = false // 场景裂变+不更换容貌时，允许生成
                } else {
                    if (!keepPose) disabled = !((refImg || positivePrompt) && modelStyle) // 相似风格时，需要至少有一个参考图/提示词+模特
                    else disabled = !(refImg || positivePrompt) // 相似风格+不更换容貌时，需要至少有一个参考图/提示词
                }
            } else {
                if (!keepPose) disabled = !(bgStyle && modelStyle) // 预设场景时，需要选择模特+场景
                else disabled = !bgStyle // 预设场景+不更换容貌时，需要选择场景
            }
        } else {
            if (definedFlag == 1) disabled = true
            else {
                disabled = [...poseStyleList, ...customPoseStyleList].length == 0
                if (!keepPose) disabled = disabled || !modelStyle
                if (!keepBkg) disabled = disabled || !bgStyle
            }
        }

        return (
            clothLoadingFlag ||
            clothLoadingFlag2 ||
            !maskIsReady ||
            disabled
        )
    }, [
        imageType,
        dragScale,
        definedFlag,
        refImg,
        positivePrompt,
        clothLoadingFlag,
        clothLoadingFlag2,
        maskIsReady,
        bgStyle,
        modelStyle,
        poseStyleList,
        customPoseStyleList,
        keepPose,
        keepBkg,
        isSceneFracturing
    ])

    const leftBottom = (
        <div id="tour_target_create">
            <div className={styles.create_styl}>
                <span style={{marginRight: '4px', fontSize: 12}}>{imageType == IMAGE_TYPE.CLOTHES ? '每张模特图生成图片数' : '生成图片数'}</span>
                <span className={`${styles.num_btn} ${createNum == 1 && styles.disabled}`} style={{marginRight: '8px'}} onClick={(e) => onCreateNumChange('reduce')}>-</span>
                <span style={{marginRight: '4px'}}>{createNum}</span>
                <span className={`${styles.num_btn} ${createNum == 4 && styles.disabled}`} style={{marginRight: '10px'}} onClick={(e) => onCreateNumChange('add')} >+</span>
                <span style={{fontSize: 12}}>张</span>
                <span className={styles.use_point}>消耗 <img src={require('@assets/images/arcbean2.png')}/><span>{consumAmount}</span></span>
            </div>
            <Button
                className={styles.create_btn}
                disabled={createBtnDisabled}
                type="primary"
                loading={isLoading}
                onClick={createHandle}
            >{'生 成'}</Button>
        </div>
    )

    return (
        <div className={styles.mainBox}>
            <div className={`${styles.modelLeftIndex} ${isDrawerOpen && styles.modelLeftIndex_radius}`}>
                <div className={styles.modelLeftbox}>
                {
                    initialized ?
                    (
                        imageType == IMAGE_TYPE.COMMODITY ? CommodityRender :
                        imageType == IMAGE_TYPE.CLOTHES ? TryonRender :
                        imageType == IMAGE_TYPE.MODEL ? ModelRender :
                        imageType == IMAGE_TYPE.DRESSFORM ? DressformRender : ""
                    ) :
                    null                   
                }
                </div>
                {leftBottom}
                {/* { !maskIsReady && <div className={styles.left_mask}></div> } */}
            </div>
            <div ref={drawerRef} className={`${styles.modelDrawerbox} ${isDrawerOpen && styles.drawer_open}`}>
                <Drawer
                    type={modelOrScene}
                    
                    modelStyle={modelStyle} // 模特
                    onModelChange={onModelChange}

                    poseNameList={(drawerPoseIsCustom ? customPoseStyleList : poseStyleList).map(item => item.name)} // 姿势
                    onPoseChange={drawerPoseIsCustom ? onCustomPoseChange : onPoseChange}
                    drawerPoseIsCustom={drawerPoseIsCustom} // 自定义姿势：1-true；0-false
                    
                    sceneStyle={bgStyle} // 场景
                    onSceneChange={onSceneChange}
                    backgroundType={drawerBackgroundType} // 0-预设风格；1-预设参考图

                    getEdgeUrl={props.onEdgeChange} // 更新softedge

                    isDrawerOpen={isDrawerOpen}
                    setIsDrawerOpen={() => drawerTriggerDebounce(false)}

                    onRefresh={() => setRefreshRecent({})}
                />
            </div>
        </div>
    )
    
}

export default ModelLeftIndex