import { useState, useRef, useEffect, useContext, useMemo, } from "react"
import styles from './index.module.scss'
import useResizeObserver from "@utils/useResizeObserver"
import { maskDispose } from "@utils/workflowOfPrediction"
import Utils from '@utils/utils'
import { GenerateContext } from "@utils/GenerateContext"
import Drag from "./Drag"
import ToolBox from '../ToolBox/ToolBox';
import { Spin } from "antd"
import Decimal from 'decimal.js'

// 推荐尺寸(recommend) / 原图尺寸(original) / 其他(1:1、3:4、9:16)
const getClipSize = (imgRatio, imgSize, containSize) => {
    if (!containSize) return
    if (!imgSize) return
    let ratioW, ratioH
    if (imgRatio == 'custom') { // 推荐
        ratioW = imgSize.width
        ratioH = imgSize.height
    } else { // 其他比例 1:1、3:4、9：16
        const ratio = imgRatio.split(':')
        ratioW = Number(ratio[0])
        ratioH = Number(ratio[1])
    }
    const size = Utils.getImgMaxSizeInContainer(ratioW, ratioH, containSize.width, containSize.height)
    return size
}

const MaskModelWithBgMoveable = (props) => {
    const isMounted = useRef(true)
    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    const {
        bgImage, setImgClipSize,
        globaluploadLoading, setGlobaluploadLoading,
        coordDragRef, setCoordDrag,
    } = useContext(GenerateContext)

    const [deskSize, setDeskSize] = useState()
    const [deskWidth, setDeskWidth] = useState()
    const [deskHeight, setDeskHeight] = useState()
    const [inputImageSize, setInputImageSize] = useState() // 输入图尺寸
    const [imgSize, setImgSize] = useState() // 图片尺寸
    const [bgSize, setBgSize] = useState() // 背景尺寸
    const [canvasSize, setCanvasSize] = useState() // 画布最大尺寸

    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setDeskSize({
                width: entry.contentRect.width,
                height: entry.contentRect.height
            })
            setDeskWidth(entry.contentRect.width)
            setDeskHeight(entry.contentRect.height)
        }
    })
    // 获得inputImageSize
    useEffect(() => {
        Utils.getImageDimensions(props.inputImage).then(({width, height}) => {
            setInputImageSize({ width, height })
        })
    }, [props.inputImage])
    // 获得imgSize
    useEffect(() => {
        Utils.getImageDimensions(props.segmentResult[2]).then(({width, height}) => {
            // console.log('getImageDimensions', segmentResult[2], width, height)
            setImgSize({ width, height })
        })
    }, [props.segmentResult])
    // 由于加载url比较慢，所有需要先转成base64
    const [bgImageBase64, setBgImageBase64] = useState()
    useEffect(() => {
        if (bgImage) {
            Utils.urlToBase64(bgImage).then(base64 => setBgImageBase64(base64))
        } else {
            setBgImageBase64()
        }
    }, [bgImage])
    useEffect(() => {
        if (!imgSize) return
        if (!bgImageBase64) { // 如果bgImage不存在，说明是"预设风格"
            const clipSize = getClipSize(props.imgRatio, imgSize, imgSize)
            setBgSize(clipSize)
            coordDragRef.current = null // 重置coordDrag
            setCoordDrag(coordDragRef.current)
            return
        }
        Utils.getImageDimensions(bgImageBase64).then(({width, height}) => {
            setBgSize({ width, height })
        })
    }, [props.imgRatio, bgImageBase64, imgSize])
    useEffect(() => {
        if (!deskWidth || !deskHeight) return
        if (!bgSize) return
        const size = Utils.getImgMaxSizeInContainer(
            bgSize.width,
            bgSize.height,
            deskWidth,
            deskHeight
        )
        setCanvasSize(size)
    }, [deskWidth, deskHeight, bgSize])

    // 根据ratio裁剪后的图片尺寸
    const imgClipSize = useMemo(() => {
        return getClipSize(props.imgRatio, imgSize, imgSize)
    }, [props.imgRatio, imgSize])

    // 根据ratio裁剪后的背景尺寸
    const bgClipSize = useMemo(() => {
        return getClipSize(props.imgRatio, imgSize, bgSize)
    }, [props.imgRatio, imgSize, bgSize])
    
    // 根据ratio裁剪后的画布高亮尺寸
    const canvasClipSize = useMemo(() => {
        return getClipSize(props.imgRatio, imgSize, canvasSize)
    }, [props.imgRatio, imgSize, canvasSize])

    // 保存到GenerateContext中
    useEffect(() => {
        setImgClipSize(imgClipSize)
    }, [imgClipSize])

    // useEffect(() => {
    //     if (!(deskSize && inputImageSize && imgSize && imgClipSize && bgSize && bgClipSize && canvasSize && canvasClipSize)) return
    //     console.log('========= begin =========')
    //     console.log('容器的尺寸：', `${deskSize.width}*${deskSize.height}`)
    //     console.log('输入图尺寸：', `${inputImageSize.width}*${inputImageSize.height}`)
    //     console.log('图片尺寸：', `${imgSize.width}*${imgSize.height}`)
    //     console.log('图片裁剪后尺寸：', `${imgClipSize.width}*${imgClipSize.height}`)
    //     console.log('背景尺寸：', `${bgSize.width}*${bgSize.height}`)
    //     console.log('背景裁剪后尺寸：', `${bgClipSize.width}*${bgClipSize.height}`)
    //     console.log('画布尺寸：', `${canvasSize.width}*${canvasSize.height}`)
    //     console.log('画布裁剪后尺寸：', `${canvasClipSize.width}*${canvasClipSize.height}`)
    //     console.log('========= end =========')
    // }, [deskSize, inputImageSize, imgSize, imgClipSize, bgSize, bgClipSize, canvasSize, canvasClipSize])

    const isReady = useMemo(() => {
        return imgSize && imgClipSize && bgSize && bgClipSize && canvasSize && canvasClipSize
    }, [imgSize, imgClipSize, bgSize, bgClipSize, canvasSize, canvasClipSize])

    // 重新上传
    const [loading, setLoading] = useState(false)
    const reUpload = async (...args) => {
        setLoading(true)
        setGlobaluploadLoading(true)
        try {
            return await props.reUpload(...args)
        } catch {
            setGlobaluploadLoading(false)
        } finally {
            setLoading(false)
        }
    }

    // 获取npy文件
    useEffect(() => {
        if (props.segmentResult.length == 0) return
        if (!props.aiInteractiveNpy) {
            maskDispose(props.segmentResult, () => !isMounted.current).then(res => {
                props.onNpyChange(res?.npy)
            })
        }
    }, [props.segmentResult, props.aiInteractiveNpy])

    return (
        <div className={styles.Container}>
            <div className={styles.MaskDeskWrap} ref={targetRef}>
            {
                isReady &&
                <div className={styles.MaskDesk} style={{ width: canvasSize.width, height: canvasSize.height }}>
                    <Drag
                        bgImage={bgImageBase64}
                        imgRatio={props.imgRatio}
                        segmentResult={props.segmentResult}
                        imgSize={imgSize}
                        imgClipSize={imgClipSize}
                        bgSize={bgSize}
                        bgClipSize={bgClipSize}
                        canvasSize={canvasSize}
                        canvasClipSize={canvasClipSize}
                    />
                    <div className={`${styles.ToolBox}`}>
                        <ToolBox
                            border={props.border}
                            reUpload={reUpload}
                            mattingOpt={props.mattingOpt}
                            composeOpt={props.composeOpt}
                        />
                    </div>
                </div>
            }
            </div>
            {
                globaluploadLoading &&
                <div className={styles.Loading}>
                    <Spin size="large" />
                </div>
            }
        </div>
    )
}



export default (props) => {
    const { bgImage } = useContext(GenerateContext)
    const [inputImage] = useState(props.inputImage)
    const [segmentResult] = useState(props.segmentResult)

    const key = useMemo(() => {
        return inputImage 
    }, [bgImage, inputImage])

    return <MaskModelWithBgMoveable key={key} {...props} />
}