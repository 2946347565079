import { Input, Button, message } from "antd";
import { useEffect, useRef, useState } from "react";
import styles from './index.module.scss'
import Captcha from '@view/compoents/captcha'
import Utils from '@utils/utils'
import API from '@api/api'
import eventBus from "../../../../../utils/eventBus";

export default function VerityCode (props) {
    const [value, setValue] = useState(props.value)
    const [isCountdown, setIsCountdown] = useState(false) // 正在倒计时，不能发送验证码
    const countdownRef = useRef(60) // 验证码倒计时
    const [countdown, setCountdown] = useState(60) // 验证码倒计时
    const [loading, setLoading] = useState(false) // 发送验证码中
    const [intervalId, setIntervalId] = useState()

    useEffect(() => {
        return () => {
            intervalId && clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const handleClick = async () => {
        try {
            if (isCountdown) return
            // 触发mobile检查
            await props.onValidate()
            eventBus.emit('captcha', () => onBizResultCallback())
        } catch (err) {
            console.error(err)
        }
    }

    const onBizResultCallback = async () => {
        if (isCountdown) return
        try {
            setLoading(true)
            await Utils.delayFunc(500)
            const res = await API.userSendCode({mobile: props.mobile})
            if (res.code != 0) throw new Error(res.message)
            message.success('发送成功！')
            setIsCountdown(true)
            let intervalId = setInterval(() => {
                countdownRef.current -= 1;
                setCountdown(countdownRef.current);
                if (countdownRef.current === 0) {
                    clearInterval(intervalId)
                    setIsCountdown(false)
                    setCountdown(60)
                    countdownRef.current = 60
                }
            }, 1000);
            setIntervalId(intervalId)
        } catch (err) {
            console.error(err.message)
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={styles.Container}>
            <Input
                value={value}
                tabIndex="3"
                size="large"
                maxLength={6}
                placeholder="请输入验证码"
                prefix={<img style={{width: 16}} src={require('@assets/images/icon_Validation.png')} alt="" />}
                onChange={props.onChange}
            />
            <Button
                tabIndex="2"
                size="large"
                loading={loading}
                className={styles.CodeButton}
                onClick={handleClick}
            >
                {isCountdown?`${countdown}秒`: '验证码'}
            </Button>
        </div>
    )
}