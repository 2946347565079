import React, { useState, useMemo, useRef, useEffect } from 'react';
import '../../aiEdit.scss'
import './magicErase.scss'
import { message, Dropdown, Slider, Divider, Tooltip, Button, Spin} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Utils from '@utils/utils'
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from "react-router-dom";
import API from '@api/api.js';
import axios from 'axios'
import ImgComparer from '../imgComparer'
import DragAndScale from '../dragAndScale/dragAndScale'
import MagicEraseCanvas from './magicEraseCanvas';
import ToolBox from '../toolBox/toolBox';
import {forwardRef, useImperativeHandle} from 'react'
import icon_Eraser from '@assets/images/icon_Eraser.svg'
import { AppContext } from '@utils/AppContext';
import { useContext } from 'react'
import { AIEDIT_SOURCE } from '@utils/CONST';
import { AIEditContext } from '../../AIEditContext';
import eventBus from '@utils/eventBus';

const MagicErase = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onChange: () => {
            return currentImgRef.current || props.url
        },
        handleDraw,
        repairSizeChange,
        createClick
    }))

    const {needPay, setNeedPay, getImgWithWatermark,  eraserSize, setEraserSize,
        operationKey, setOperationKey,
        isLoading2, setIsLoading2,
        isDraw, setIsDraw} = useContext(AIEditContext)
    const { aiEditDownloadWithLoadingFunc } = useContext(AppContext) // 触发更新flag

    const isMounted = useRef(true) // 判断组件是否已销毁
    const drawRef = useRef(null);
    const wrapRef = useRef()
    // const [operationKey, setOperationKey] = useState('eraser');
    const [repairSize, setrepairSize] = useState(50);
    // const [eraserSize, setEraserSize] = useState(50);
    const [urlBg, setUrlBg] = useState();
    const [orignPicBase64, setOrignPicBase64] = useState()
    const [orignPic, setOrignPic] = useState();
    const [isLoading1, setIsLoading1] = useState(false);
    // const [isLoading2, setIsLoading2] = useState(false);
    const [compareBtn, setCompareBtn] = useState(false);
    const currentImgRef = useRef()
    const [currentImg, setCurrentImg] = useState('');
    const [currentImgWithWatermark, setCurrentImgWithWatermark] = useState('')
    const [reswidth, setWidth] = useState();
    const [resheight, setHeight] = useState()
    const [displayScale, setDisplayScale] = useState(1)
    const imgWidthRef = useRef()
    const [imgWidth, setImgWidth] = useState() // 图片原始宽度
    const imgHeightRef = useRef()
    const [imgHeight, setImgHeight] = useState() // 图片原始高度
    const [downloading, setDownloading] = useState();
    const [redoDataArrLength, setRedoDataArrLength] = useState(0); // 用于恢复撤销
    // const [isDraw, setIsDraw] = useState([]); // 用于判断是否画线
    const location = useLocation();
    const [windowSize, setWindowSize] = useState()
    const [inited, setInited] = useState(false)
    const [fabricInstance, setFabricInstance] = useState()
    const [bgInstance, setBgInstance] = useState()
    const redoDataArrRef = useRef([])
    const dragScaleRef = useRef()

    const calcCanvasSize = () => {
        const containerWidth = wrapRef.current?.clientWidth - 120 - 150
        const containerHeight = wrapRef.current?.clientHeight
        const size = Utils.getImgMaxSizeInContainer(
            imgWidthRef.current,
            imgHeightRef.current,
            containerWidth,
            containerHeight
        )
        setDisplayScale(size.width / imgWidthRef.current)
        console.log('size.width / imgWidthRef.current', size.width / imgWidthRef.current)
        setWidth(size.width)
        setHeight(size.height)
    }

    useEffect(() => {
        const onWindowResize = (e) => {
            calcCanvasSize()
        }
        window.addEventListener('resize', onWindowResize)
        return () => {
            isMounted.current = false
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false)
    const ctrlKeyPressedRef = useRef(false)
    const keyZPressedRef = useRef(false)
    useEffect(() => {
        const keydown = ev => {
            if ((ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17) && !ctrlKeyPressedRef.current) { // ctrl+Z 可撤销操作
                ctrlKeyPressedRef.current = true
                setCtrlKeyPressed(true)
                if (keyZPressedRef.current) {
                    // revoke()
                }
            }
            if ((ev.key == 'z' || ev.code == 'KeyZ' || ev.keyCode == 90) && !keyZPressedRef.current) { // ctrl+Z 可撤销操作
                keyZPressedRef.current = true
                if (ctrlKeyPressedRef.current) {
                    // revoke()
                }
            }
        }
        const keyup = ev => {
            if (ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17) {
                ctrlKeyPressedRef.current = false
                setCtrlKeyPressed(false)
            }
            if (ev.key == 'z' || ev.code == 'KeyZ' || ev.keyCode == 90) {
                keyZPressedRef.current = false
            }
        }
        // 监听键盘按下
        document.addEventListener('keydown', keydown)
        document.addEventListener('keyup', keyup)
        return () => {
            document.removeEventListener('keydown', keydown)
            document.removeEventListener('keyup', keyup)
        }
    }, [])

    // 初始化1
    useEffect(() => {
        if(props.url) {
            Utils.urlToBase64(props.url).then(base64 => {
                setOrignPicBase64(base64)
                setOrignPic(props.url)  // 生成图         
            })
        }
    }, [])

    // 初始化2
    useEffect(() => {
        if (!orignPic) return
        Utils.getImageDimensions(orignPic).then(({width, height}) => {
            imgWidthRef.current = width
            imgHeightRef.current = height
            setImgWidth(width)
            setImgHeight(height)
            calcCanvasSize()
            setInited(true)
        })
    }, [orignPic])

    // useEffect(() => {        
    //     return () => {
    //         if (isMounted.current) return
    //         props.onChange(currentImg || props.url)
    //     }
    // }, [currentImg])    

    useEffect(() => {
        redoDataArrRef.current = []
        setRedoDataArrLength(0)
        setIsDraw([])
        clear()
    }, [orignPic])
    
    const createClick = (val) => {
        if(val == '1') {
            setIsLoading1(true)
        } else {
            setIsLoading2(true)
        }
        if(!drawRef.current) {
            message.warning('请先返回重新涂抹需要擦除的区域！', 10)
            setIsLoading1(false)
            setIsLoading2(false)
            return
        }
        Utils.resizeImage(drawRef.current.getDataURL(), imgWidthRef.current, imgHeightRef.current).then((res) => { //  将 mask resize成原图大小
            Utils.convertTransparentWhiteToOpaque(res).then((newBase64Image) => {
                const file = Utils.convertBase64ToFile(newBase64Image)
                Utils.uploadFile(file).then(url => {
                    if (!url) {
                        setIsLoading1(false)
                        setIsLoading2(false)
                        return
                    }
                    airemovePrediction(url)
                })
            })
          
        })
    }

    const repairSizeChange = (value, key) => {
        if(key == 'repair') {
            setrepairSize(value)
        } else if(key == 'eraser') {
            setEraserSize(value)
        }
    }

    const handleDraw = (key) => {
        setOperationKey(key)
        clear()
        redoDataArrRef.current = []
        setRedoDataArrLength(0)
        setIsDraw([])
    }
    const undo = () => {
        const saveData = drawRef.current.getSaveData()
        redoDataArrRef.current.push(saveData)
        setIsDraw(saveData.lines)
        setRedoDataArrLength(redoDataArrRef.current.length)
        drawRef.current.undo()
    }
    const redo = () => {
        if(redoDataArrRef.current.length <= 0) return
        const redoData = redoDataArrRef.current.pop();
        drawRef.current.loadSaveData(redoData)
        setRedoDataArrLength(redoDataArrRef.current.length)
    }
    const clear = () => {
        if(!drawRef.current) return 
        drawRef.current.clear()
    }

    const airemovePrediction = (maskUrl) => {
        // 魔法擦除埋点
        Utils.useroplog(sessionStorage.getItem('oplog_imageType'), 'algorithm', 'aiRemove', {'AI_edit_pic': orignPic})
        API.airemovePrediction({
            is_hand: 0, // parseInt(ishand), // 0表示擦除， 1表示修复 // 231107 与张闯对接口参数，写死0
            init_image: '', // inputImage?inputImage: orignPic, // 修复的时候需要原图 // 231107 与张闯对接口参数，写死空字符串
            pend_image: orignPic,
            mask_image: maskUrl,
            prompts: 'photograph of a beautiful empty scene, highest quality settings'
        }).then((res) => {
            if(res.code == 0) {
                airemovePredictionResult(res.data).then(url => {
                    message.success('处理成功！')
                    currentImgRef.current = url
                    setCurrentImg(url)
                    setOrignPic(url)
                    downloadUrl.current = null
                }).catch(err => {
                    console.error('擦除失败')
                }).finally(() => {
                    setIsLoading1(false)
                    setIsLoading2(false)
                })
            } else {
                message.error(res.message)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const airemovePredictionResult = (id)  => {
        return new Promise((resolve, reject) => {
            const loopFunc = () => {
                axios.post(API.airemovePredictionUrl + id,  {}, {
                    headers: {
                        'Content-type' : 'application/json',
                        'Ps-Auth-Token': localStorage.getItem('token')
                    }
                }).then(res => {
                    if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                        resolve(res.data.data.output[0])
                    } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                        const error = Utils.parseErrorCode(`[${res.data.data.errcode}]`)
                        eventBus.emit('notification', '检测失败', error.message, 'error', error.code)
                        reject()
                    } else {
                        if (!isMounted.current) return reject()
                        setTimeout(() => {
                            loopFunc()
                        }, 400)
                    }
                })
            }
            loopFunc()
        })
    }

    const resetClick = () => {
        setCompareBtn(false)
        currentImgRef.current = null
        setCurrentImg()
        setOrignPic(props.url)
        downloadUrl.current = null
        dragScaleRef.current.resetCanvas()
    }

    const compareClick = () => {
        if (!compareBtn) {
            getImgWithWatermark(currentImg).then(base64 => {
                setCurrentImgWithWatermark(base64)
                setCompareBtn(true)
            })
        } else {
            setCompareBtn(false)
        }
    }

    const downloadUrl = useRef()
    async function commonDownloadFunc() {
        if (downloadUrl.current) {
            Utils.downUrl(downloadUrl.current, sessionStorage.getItem('oplog_imageType'), false)
            message.success('下载成功')
        } else {
            downloadUrl.current = await aiEditDownloadWithLoadingFunc(
                currentImg,
                AIEDIT_SOURCE.AI_REMOVE,
                sessionStorage.getItem('oplog_imageType'),
                needPay
            )
            if (downloadUrl.current) setNeedPay(false)
        }
    }

    const getIsDraw = (lineArr) => {
        setIsDraw(lineArr)
    }

    const [moveable, setMoveable] = useState()
    const onDragAndScaleChange = ({moveable}) => {
        setMoveable(moveable)
    }
   
    const rightContent = (
        <div className='edit_right_c' style={{position: 'relative'}} ref={wrapRef}>
        {
            inited ?
            <div className='wrap_box wrap_box_'>
                <div className='dragAndScale_box'>
                    <DragAndScale
                        ref={dragScaleRef}
                        zoomAllowed={!ctrlKeyPressed}
                        min={1}
                        max={8}
                        onChange={onDragAndScaleChange}
                        canvasList={[fabricInstance, bgInstance]}
                        disabled={compareBtn? true: false}
                        options={[
                            { label: '适应屏幕', value: 1 },
                            // { label: '实际大小', value: Number((1 / displayScale).toFixed(2)) },
                            { label: '100%', value: 1 },
                            { label: '200%', value: 2 },
                            { label: '300%', value: 3 },
                            { label: '400%', value: 4 },
                        ]}
                    />
                    {compareBtn?<p>对比时可直接使用滚轮缩放、按住鼠标移动</p>: null}
                </div>
                {
                    compareBtn && 
                    <div style={{height: resheight, width: reswidth}} >
                        <ImgComparer
                            leftImage={props.url}
                            rightImage={currentImgWithWatermark}
                        />
                    </div>
                }
                <div
                    style={{
                        position: compareBtn ? 'absolute' : 'relative',
                        top: compareBtn ? '-9999px' : '0',
                        left: compareBtn ? '-9999px' : '0',
                        height: resheight,
                        width: reswidth
                    }}
                >
                    <MagicEraseCanvas
                        ref={drawRef}
                        size={eraserSize} 
                        url={orignPic}
                        moveable={moveable}
                        displayScale={displayScale}
                        displayWidth={reswidth}
                        displayHeight={resheight}
                        ctrlKeyPressed={ctrlKeyPressed}
                        setFabricInstance={(a,b) => {setBgInstance(a); setFabricInstance(b)}}
                        getIsDraw={getIsDraw}
                        changeBrushSize={offset => setEraserSize(pre => pre+offset)}
                    />
                </div>
                <ToolBox
                    showCompare={compareBtn}
                    compare={{disabled: !currentImg, trigger: compareClick}}
                    revoke={{disabled: isDraw.length == 0, trigger: undo}}
                    restore={{disabled: redoDataArrLength == 0, trigger: redo}}
                    reset={{disabled: !currentImg, trigger:resetClick}}
                    download={{disabled: !currentImg, trigger: commonDownloadFunc}}
                    uploadCb={props.uploadCb}
                />
            </div> : 
            <div style={{display:"flex", justifyContent:"center", alignItems: "center", height: "100%"}}>
                <Spin size="large" />
            </div>
        }
        </div>
    )

    return (
        <div className='edit_cont MagicErase'>
            <div className='edit_right'>
            {
                !props.url ?
                props.defaultPage:
                rightContent     
            }   
            </div>
        </div>
    )
})

export default MagicErase;
