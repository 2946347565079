import styles from './VersionList.module.scss'
import { Segmented } from 'antd';
import VersionTips from './VersionTips';
import { useMemo } from 'react';

const VersionList = (props) => {
    const options = useMemo(() => {
        return props.options.map(item => ({
            label: (
                <p className={`${styles.SegmentedContent} ${item.isNew && styles.new}`}>
                    {item.name}  
                    <VersionTips {...item} />
                </p>
            ),
            value: item.value
        }))
    }, [props.options])

    return (
        <div className={styles.VersionList}>
            <Segmented
            block={true}
            options={options}
            value={props.version}
            onChange={props.onChange}
        />
        </div>
    )
}
export default VersionList