import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '@utils/AppContext';
import { Button, message, Modal, Spin, Tooltip, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ProjectTaskList from './projectTaskList';
import './index.scss'
import API from '@api/api';
import utils from '@utils/utils.js'
import {workflowOfReplica} from '@utils/workflowOfBuildTask';
import GoBack from '@view/compoents/goBack/goBack';
import { IMAGE_TYPE, HOME_PAGE_TYPE, UPLOAD_SOURCE } from '@utils/CONST';
import PSAIModal from '@PSAIComponents/PSAIModal';

const FavorDetail = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const { update, forceUpdate, updateConfig, globalHomePageType } = useContext(AppContext) // 触发更新flag
    const [goBack, setGoBack] = useState(location?.state?.goBack)
    const [isSelected, setIsSelected] = useState(false)
    const [isButton, setIsButton] = useState(true);
    const [selectedPics, setSelectedPics] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [favoriteList, setFavoriteList] = useState([])
    const taskRef = useRef()
    const fetchType = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return IMAGE_TYPE.COMMODITY
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return IMAGE_TYPE.MODEL
        }
    }, [globalHomePageType])

    useEffect(() => {
        updateConfig({sidebarKey: 0})
    }, [])

    useEffect(() => {
        API.getAllFavoriteList({
            type: fetchType,
            outputType: 'all'
        }).then(res => {
            if (!res || res?.code !== 0 || !res?.data) {
                console.log('get FavorDetail error', res?.code, res?.message);
                if (res?.message) {
                    message.error(res.message)
                }
                return
            }
            let data = res.data.filter(item => item.results.length > 0)
            setFavoriteList(data.reverse())
        }).catch((error) => {
            console.log(error)
            setFavoriteList([])
        })
    }, [update])

    const key = 'replica'

    useEffect(() => {
        return () => {
            messageApi.destroy(key)
        }
    }, [])


    async function onReplica (task, fileList) {
        taskRef.current = task
        const taskId = task.taskId
        try {
            for (let index = 0; index < fileList.length; index++) {
                const msgContent = `复用生成中[${Number(index)+1}/${fileList.length}]...`
                messageApi.open({
                    key,
                    type: 'loading',
                    content: msgContent,
                    duration: 0,
                })
                const tempFile = fileList[index]
                // AAA
                const validate = await utils.validateImageFile(tempFile)
                if (!validate) continue
                const imageType = task.imageType
                let source
                if (imageType == IMAGE_TYPE.MODEL) source = UPLOAD_SOURCE.MODEL
                else if (imageType == IMAGE_TYPE.DRESSFORM) source = UPLOAD_SOURCE.DRESSFORM
                const legalRes = await utils.checkImageIslegal(tempFile, 1, source)
                if (!legalRes) continue
                if(!legalRes.isLegal) {
                    PSAIModal.confirm({
                        content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                        okText: '重新上传',
                        cancelText: '取消',
                        onOk() {
                            utils.openFile(true).then((res) => {
                                if(res) onReplica(taskRef.current, res)
                            })
                        },
                    })
                    return false
                }
                const res = await workflowOfReplica(
                    taskId,
                    task.imageType,
                    task.projectName,
                    legalRes.data,
                    legalRes.imageAntispamId
                )
                if (res) {
                    // 第一个复刻完成后，触发自动跳转到生成中的任务处
                    if (index == 0) forceUpdate({jumpOnce: true})
                    else forceUpdate({})
                }
            }
        } finally {
            messageApi.destroy(key)
        }
    }

    const goBackFunc = () => {
        if (goBack) {
            navigate(goBack.url, {state: { type: goBack.type }})
        } else {
            navigate('/home/homemain/myProject')
        }
    }

    const [downLoading, setDownLoading] = useState(false)
    const downloadPic = () => {
        setDownLoading(true)
        const selectTasks = favoriteList.filter(item => item.results.length > 0 && selectedPics.includes(item.results[0].id))
        API.favorBatchDownload({
            taskIds: selectTasks.map(item => item.taskId)
        }).then(res => {
            if (res?.code === 0 && res?.message === 'succeed') {
                return utils.downloadFileWithToken(res?.data).then(() => {
                    message.success('下载成功');
                }).catch(() => {
                    message.warning('下载失败，请稍后重试');
                })
            } else {
                message.warning(res?.message || '下载失败，请联系管理员')
            }
        }).finally(() => {
            setDownLoading(false)
        })
    }

    const reqParams = useMemo(() => {
        const taskList = favoriteList
        if (!taskList?.length) {
            return;
        }
        const picUrlTaskMap = {};
        taskList.forEach(eachTask => {
            const taskId = eachTask?.taskId;
            eachTask?.results.forEach(picInfo => {
                picUrlTaskMap[picInfo?.id] = taskId
                if(picInfo.status == 'succeeded') {
                    setIsButton(true)
                }
            })
        })

        const res = [];
        selectedPics.forEach(eachPicId => {
            const taskId = picUrlTaskMap[eachPicId];
            const taskIndex = res.findIndex(eachTaskGroup => eachTaskGroup.taskId === taskId);
            if (taskIndex >= 0) {
                res[taskIndex].imageIds.push(eachPicId);
            } else {
                res.push({
                    taskId: taskId,
                    imageIds: [eachPicId]
                })
            }
        })

        return res
    }, [favoriteList, selectedPics])

    const deletePhotoDetails = () => {
        // 需要把选中的 selectedPics 按照 projectDetail 里的组分类
        return API.picBatchMove(reqParams).then(res => {
            // console.log('res: ', res)
            if (res?.code === 0 && res?.message === 'succeed') {
                message.success('删除成功')
                setSelectedPics([])
                forceUpdate({});
            } else {
                message.error('删除失败，请联系管理员')
            }
        })
    }

    // 全选/取消全选
    const selectAll = () => {
        if (isSelectedAll) {
            setSelectedPics([])
        } else {
            setSelectedPics((favoriteList?.map(task => task.results.map(res => res.id)) || []).flat())
        }
    }

    // 判断是否已全选
    const isSelectedAll = useMemo(() => {
        const all = (favoriteList?.map(task => task.results.map(res => res.id)) || []).flat()
        if (all.length == 0 || all.length != selectedPics.length) return false

        const sortedArr1 = all.sort()
        const sortedArr2 = selectedPics.slice().sort()

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true
    }, [favoriteList, selectedPics])

    const onDelete = () => {
        PSAIModal.confirm({
            title: "删除结果图",
            content: "请确认是否删除这些结果图",
            onOk: deletePhotoDetails
        })
    }

    return (
        <div className='PhotoProjectDetail '>
            {contextHolder}
            <div className='top'>
                <div className='head'>
                    <GoBack goBack={() => goBackFunc()} />
                    <div className='project-name'>我的收藏</div>
                </div>
                <div className='options_opts'>
                    {!isSelected && isButton && <Button className='options_select' onClick={() => setIsSelected(true)}>选择</Button>}
                    {isSelected && 
                    <>
                        <Button onClick={() => selectAll()}>{isSelectedAll ? '取消全选' : '全选'}</Button>
                        <Button disabled={selectedPics.length==0} loading={downLoading} onClick={() => selectedPics.length > 0 ? downloadPic() : message.warning('请选择一条记录')}>下载</Button>
                        <Button disabled={selectedPics.length==0} onClick={() => selectedPics.length > 0 ? onDelete() : message.warning('请选择一条记录')} >删除</Button>
                        <Button
                            style={{background: '#edf0ff'}}
                            onClick={() => {
                                setIsSelected(false);
                                setSelectedPics([])
                            }}
                        >取消</Button>
                    </>
                    }
                </div>
            </div>
            <Spin
                wrapperClassName="task_content_spin"
                spinning={favoriteList?.length == 0}
                size="large"
            >
                <div className='content _beauty_scrollbar'>
                {
                    favoriteList?.length != 0 &&
                    <ProjectTaskList
                        showFavorList={false}
                        taskList={favoriteList}
                        isSelected={isSelected}
                        selectedPics={selectedPics}
                        setSelectedPics={setSelectedPics}
                        projectId={location?.state?.id}
                        replicaCb={onReplica}
                    />
                }
                </div>
            </Spin>
        </div>
    )

}

export default FavorDetail