import { useContext, useMemo } from "react"
import MaskDressform from "../components/MaskDressform/MaskDressform"
import MaskModel from "../components/MaskModelWithBgMoveable"
// import MaskModel from "../components/MaskModel/MaskModel"
import UploadModelAndCommodity from "../components/UploadModelAndCommodity"
import { IMAGE_TYPE } from '@utils/CONST'
import { GenerateContext } from "@utils/GenerateContext"

const SubCompModel = (props) => {
    const {
        imgRatio,
        inputImage,
        maskMoveable,
    } = useContext(GenerateContext)

    return (
        props.segmentResult?.length > 0 ?
        // 保持原图场景/场景裂变，人物不可移动
        maskMoveable ?
        <MaskModel
            key={props.segmentResult[0]}
            imgRatio={imgRatio}
            imageType={props.imageType}
            aiInteractiveNpy={props.aiInteractiveNpy}
            inputImage={inputImage}
            segmentResult={props.segmentResult}
            reUpload={props.reUpload}
            deleteOpt={props.deleteOpt}
            mattingOpt={props.mattingOpt}
            composeOpt={props.composeOpt}
            onNpyChange={props.onNpyChange}
        /> :
        <MaskDressform
            key={props.segmentResult[0]}
            imageType={props.imageType}
            aiInteractiveNpy={props.aiInteractiveNpy}
            segmentResult={props.segmentResult}
            reUpload={props.reUpload}
            deleteOpt={props.deleteOpt}
            mattingOpt={props.mattingOpt}
            composeOpt={props.composeOpt}
            onNpyChange={props.onNpyChange}
        />
        :
        <UploadModelAndCommodity
            imageType={props.imageType}
            onBeforeUpload={props.onBeforeUpload}
        />
    )
}

export default SubCompModel