import { useMemo, useContext, useRef, useEffect, useState } from 'react'
import ProjectTask from './projectTask'
import { AppContext } from '@utils/AppContext';
import { Empty } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import utils from '@utils/utils'
import { IMAGE_TYPE } from "@utils/CONST"
import styles from './projectTaskList.module.scss'

function ProjectTaskList (props) {
    const {
        showFavorList,
        taskList,
        isSelected,
        selectedPics,
        setSelectedPics
    } = props

    const location = useLocation();
    const jumpOnceRef = useRef(false)
    const navigate = useNavigate();
    const { update, forceUpdate } = useContext(AppContext) // 触发更新flag

    const showTasks = useMemo(() => {
        const allTasks = taskList.filter(task => {
            return !(
                task.status == 'failed' ||
                (task.status == 'succeeded' && task.results.length == 0)
            )
        })
        // console.log('allTasks', allTasks.length)
        const taskMap = new Map()
        if (allTasks) {
            allTasks.sort((a, b) => a.createdTime > b.createdTime)
            for (let task of allTasks) {
                const imageType = task.imageType
                const tempTask = {...task}
                const definedFlag = tempTask.definedFlag
                const refImg = tempTask.refImg
                const keepPose = tempTask.keepPose
                const keepBkg = tempTask.keepBkg
                let url
                const loadingMaskList = tempTask.loadingMask.split(',')
                let loadingMask = ''
                if (loadingMaskList.length > 0) loadingMask = loadingMaskList[0]
                try {
                    // if (!tempTask.loadingMask) continue
                    if (loadingMask) url = new URL(loadingMask)
                } catch (err) {
                    console.error(err.message) 
                }
                const pathname = url?.pathname || ''
                const queryString = url?.search || ''
                // 对商品、模特、人台来说：refImg指自定义场景
                // 对AI试衣来说：refImg指自定义模特
                let key
                if (imageType == IMAGE_TYPE.COMMODITY) { // 商品
                    key = (refImg ? ('自定义场景' + refImg) : tempTask.backgroundTitle) + tempTask.personTitle + (pathname+queryString)
                } else if (imageType == IMAGE_TYPE.MODEL) { // 模特
                    key = (keepPose == 1 ? "保持模特" : tempTask.personTitle) + // 模特
                          (definedFlag == 1 ? '自定义场景' + refImg : tempTask.backgroundTitle) + // 场景
                          (pathname+queryString)
                } else if (imageType == IMAGE_TYPE.CLOTHES) { // 衣服
                    key = (keepBkg == 1 ? "保持场景" : tempTask.backgroundTitle) + // 场景
                          (keepPose == 1 ? "保持容貌" : tempTask.personTitle) + // 模特
                          (refImg && ('自定义模特' + refImg)) + // 自定义模特
                          (pathname+queryString)
                } else if (imageType == IMAGE_TYPE.MODEL_VIDEO) { // 模特视频
                    key = tempTask.videoTryonTitle
                } else {
                    key = tempTask.backgroundTitle + tempTask.personTitle + (pathname+queryString)
                }
                const taskResults = (
                    tempTask.results &&
                    tempTask.results.length > 0 ? tempTask.results : [{}]
                ).map((res, index) => Object.assign(res, {
                    key: res.id ? res.id : tempTask.taskId + '_' + index,
                    loadingMask: loadingMask,
                    
                    projectName: tempTask.projectName,
                    imageType: tempTask.imageType,
                    status: tempTask.status,
                    progress: tempTask.progress,
                    waitingTime: tempTask.waitingTime,
                    taskId: tempTask.taskId,
                    bookmark: tempTask.bookmark,
                    personTitle: tempTask.personTitle,
                    personRealTitle: tempTask.personRealTitle,
                    backgroundTitle: tempTask.backgroundTitle,
                    backgroundRealTitle: tempTask.backgroundRealTitle,
                    videoTryonTitle: tempTask.videoTryonTitle,
                    poseTitle: tempTask.poseTitle,
                    hairTagText: tempTask.hairTagText,
                    faceTagText: tempTask.faceTagText,
                    updatedTime: tempTask.updatedTime,
                    definedFlag: tempTask.definedFlag,
                    prompt: tempTask.prompt,
                    refImg: tempTask.refImg,
                    inputImage: tempTask.inputImage,
                    reviewStatus: tempTask.reviewStatus, // 审核状态 2-拒绝
                    resolution: tempTask.resolution, // 商品视频分辨率
                    keepPose: tempTask.keepPose, // 保持模特
                    keepBkg: tempTask.keepBkg, // 保持场景
                }))
                if (taskMap.has(key)) {
                    const taskGroup = taskMap.get(key)
                    taskGroup.results.unshift(...taskResults)
                } else {
                    tempTask.results = [...taskResults]
                    taskMap.set(key, {...tempTask})
                }
            }
        }
        const tasks = Array.from(taskMap.values())
        // console.log('showTask', tasks)
        return tasks
    }, [taskList])

    // 第一个复刻完成后，自动跳转到生成中的任务处
    useEffect(() => {
        jumpOnceRef.current = false
    }, [location])

    useEffect(() => {
        if (update?.jumpOnce) jumpOnceRef.current = false
    }, [update])

    useEffect(() => {
        if (!jumpOnceRef.current) {
            jumpOnceRef.current = true
            let jumpto = -1
            for (let i = showTasks.length - 1; i >= 0; i--) {
                const results = showTasks[i].results
                if (results.find(res => res.status != 'succeeded' && res.status != 'failed')) {
                    jumpto = i
                    break
                }
            }
            if (jumpto >= 0) {
                setTimeout(() => {
                    const targetElement = document.getElementById(`task-group-${jumpto+1}`)
                    targetElement && targetElement.scrollIntoView({ behavior: 'smooth' })
                }, 100)
            }
        }        
    }, [showTasks])

    const bookmarkTasks = useMemo(() => {
        return showTasks.map(item => item.results).flat().filter(item => item.bookmark)
    }, [showTasks])

    // 失败的任务，用来与原processing任务对比，提醒有失败的任务
    const failedTasks = useMemo(() => {
        return taskList.filter(task => task.status == 'failed')
    }, [taskList])

    const isLoop = useRef(false)
    const isMounted = useRef(true)
    const taskInprocessing = useRef([]) 

    const loopFunc = () => {
        forceUpdate({})
        if (isLoop.current && isMounted.current) {
            setTimeout(() => {
                loopFunc()
            }, 2000)
        }
    }    

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        const task = taskList.find(item => (
            (item.status != 'succeeded' && item.status != 'failed') ||
            item.results.find(item => item.hdStatus == 'started')
        ))
        if (task) {
            if (!isLoop.current) {
                isLoop.current = true
                loopFunc()
            }
        } else {
            if (isLoop.current) {
                isLoop.current = false
                props.loopEndCb && props.loopEndCb()
            }
        }
    }, [showTasks])

    // 有失败任务时提醒用户
    // useEffect(() => {
    //     if (taskInprocessing.current.length == 0) {
    //         taskInprocessing.current = [...(showTasks?.filter(task => task.status != 'succeeded') || [])]
    //     } else {
    //         let hasFailed = false
    //         for (let task of taskInprocessing.current) {
    //             if (failedTasks?.find(failed => failed.taskId == task.taskId)) {
    //                 hasFailed = true
    //                 break
    //             }
    //         }
    //         if (hasFailed) message.warning('有任务生成失败，请重试')
    //         taskInprocessing.current = [...(showTasks?.filter(task => task.status != 'succeeded') || [])]
    //     }    
    // }, [showTasks, failedTasks])

    const loadingMaskMapRef = useRef({})
    const [loadingMaskMap, setLoadingMaskMap] = useState({})

    useEffect(() => {
        const loadMaskUrls = taskList.filter(item => !item.id && item.loadingMask && !(
                item.status == 'failed' ||
                item.status == 'succeeded'
            ))
            .map(item => item.loadingMask.split(',')).flat() || []
        setTimeout(() => {
            for (let url of loadMaskUrls) {
                if (!loadingMaskMapRef.current[url]) {
                    loadingMaskMapRef.current[url] = 'processing'
                    utils.urlToBase64(url+ '?x-oss-process=style/thumbnail-width-500').then(base64 => {
                        utils.convertImgColor(base64, [[0,0,0,255], [0,0,0,0]], [['*','*','*'], [230, 230, 230]]).then(res => {
                            loadingMaskMapRef.current[url] = res
                            setLoadingMaskMap(Object.entries(loadingMaskMapRef.current).reduce((acc, [key, value]) => {
                                if (value !== 'processing') acc[key] = value
                                return acc
                            }, {}))
                        })
                    })
                }
            }
        }, 100)
    }, [taskList])

    return (
        <div className={styles.ProjectTaskList}>
        {
            showTasks && showTasks.length > 0 ?
            showTasks.map((task, index) => (
                <ProjectTask
                    key={`${task.taskId}_${task.results.length}`}
                    index={index+1}
                    taskId={task.taskId}
                    taskResult={[...task.results]}
                    imageType={task.imageType}
                    projectName={task.projectName}
                    taskPose={task.poseTitle}
                    taskModel={task.personTitle}
                    taskBg={task.backgroundTitle}
                    taskVideoAction={task.videoTryonTitle}
                    definedFlag={task.definedFlag}
                    refImg={task.refImg} // 商品自定义参考图/衣服图定制模特
                    projectId={props.projectId}
                    isSelected={isSelected}
                    selectedPics={selectedPics}
                    setSelectedPics={setSelectedPics}
                    taskStatus={task.status}
                    taskProgress={task.progress}
                    waitingTime={task.waitingTime}
                    loadingMaskMap={loadingMaskMap}
                    keepPose={task.keepPose}
                    keepBkg={task.keepBkg}
                    replicaCb={props.replicaCb}
                />
            )) :
            <div className={styles.empty_wrap}>
                <Empty description={false} />
            </div>
        }
        {
            showFavorList &&
            bookmarkTasks && bookmarkTasks.length > 0 &&
            <ProjectTask
                key={bookmarkTasks[0].taskId}
                index={''}
                taskId={bookmarkTasks[0].taskId}
                taskResult={[...bookmarkTasks]}
                imageType={bookmarkTasks[0].imageType}
                projectName={bookmarkTasks[0].projectName}
                taskPose={bookmarkTasks[0].poseTitle}
                taskModel={bookmarkTasks[0].personTitle}
                taskBg={bookmarkTasks[0].backgroundTitle}
                taskVideoAction={bookmarkTasks[0].videoTryonTitle}
                definedFlag={bookmarkTasks[0].definedFlag}
                refImg={bookmarkTasks[0].refImg} // 商品自定义参考图/衣服图定制模特
                projectId={props.projectId}
                isSelected={isSelected}
                selectedPics={selectedPics}
                setSelectedPics={setSelectedPics}
                taskStatus={bookmarkTasks[0].status}
                taskProgress={bookmarkTasks[0].progress}
                waitingTime={bookmarkTasks[0].waitingTime}
                bookmark={true}
                keepPose={bookmarkTasks[0].keepPose}
                keepBkg={bookmarkTasks[0].keepBkg}
                replicaCb={props.replicaCb}
            />
        }
        </div>

    )
}

export default ProjectTaskList