import { useState, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import Rcslider from '../rcslider';
import createModule from '@utils/arcsoft_beauty.js';
import API from '@api/api'
import { useLocation } from 'react-router-dom';
import { Button, message, Tooltip, Dropdown, Divider, Spin } from 'antd';
import ImgComparer from '../imgComparer';
import Utils from '@utils/utils';
import { LoadingOutlined } from '@ant-design/icons';
import DragAndScale from '../dragAndScale/dragAndScale'
import { fabric } from 'fabric';
import eventBus from '@utils/eventBus';
import ToolBox from '../toolBox/toolBox';
import {forwardRef, useImperativeHandle} from 'react'
import icon_Face from '@assets/images/icon_Face.svg'
import icon_Body from '@assets/images/icon_Body.svg'
import { AppContext } from '@utils/AppContext';
import { useContext } from 'react'
import { AIEDIT_SOURCE } from '@utils/CONST';
import { AIEditContext } from '../../AIEditContext';

const AIBeauty = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onChange: () => {
            return genImgRef.current || props.url
        },
        oneClickBeauty,
        saveUndoList,
    }))

    const {needPay, setNeedPay, addWatermark, getImgWithWatermark, initialized, setInitialized, nFace, setNFace} = useContext(AIEditContext)
    const { aiEditDownloadWithLoadingFunc } = useContext(AppContext) // 触发更新flag

    const isMounted = useRef(true)
    const isLoopRef = useRef(false)
    const canvasRef_0 = useRef(null);
    const fabricInstanceRef = useRef()
    const [fabricInstance, setFabricInstance] = useState()
    const dragAndScaleRef = useRef()
    const wrapRef = useRef()
    const imgRef = useRef()
    const [module, setModule] = useState();
    const [jsString, setJsString] = useState()
    const location = useLocation();
    const originImgRef = useRef()
    const [originImg, setOriginImg] = useState()
    const genImgRef = useRef()
    const [genImg, setGenImg] = useState()
    const [genImgWithWatermark, setGenImgWithWatermark] = useState()
    const [showCompare, setShowCompare] = useState()
    const [downloading, setDownloading] = useState(false)
    const [undoList, setUndoList] = useState([]) // 待撤销的操作
    const [restoreList, setRestoreList] = useState([]) // 待恢复的操作
    const imgSizeRef = useRef({width: 0, height: 0})
    const canvasSizeRef = useRef({width: 0, height: 0})
    const [canvasSize, setCanvasSize] = useState({width: 0, height: 0}) // 前后对比组件的位置
    const displayScaleRef = useRef(1)
    const [displayScale, setDisplayScale] = useState(1)
    // const [nFace, setNFace] = useState(1)

    const [eyeValue, setEyeValue] = useState(0) // 眼睛大小
    const [faceValue, setFaceValue] = useState(0) // 脸部大小
    const [cheekValue, setCheekValue] = useState(0) // 颧骨高度
    const [noseValue, setNoseValue] = useState(0) // 鼻梁高度
    const [lipValue, setLipValue] = useState(0) // 唇部

    const [chestValue, setChestValue] = useState(0) // 胸部
    const [waistValue, setWaistValue] = useState(0) // 腰部
    const [hipValue, setHipValue] = useState(0) // 臀部
    const [legsSlimValue, setLegsSlimValue] = useState(0) // 瘦腿
    const [legsLengthenValue, setLegsLengthenValue] = useState(0) // 长腿

    // face_shrink_shape 瘦脸 脸部大小
    // cheek_lift_shape 提脸颊 颧骨高度
    // lip_plump_differ_shape 丰唇
    // nose_augment_shape 高鼻梁

    // chest_plump 胸部
    // waist_slim 腰部
    // hip_plump 臀部丰满
    // legs_slim 瘦腿
    // legs_lengthen 长腿
    const faceArray = useMemo(() => {
        return [
            { name: 'eye_enlarge_shape', value: eyeValue, label: '大眼', onChange: setEyeValue },
            { name: 'face_shrink_shape', value: faceValue, label: '瘦脸', onChange: setFaceValue },
            { name: 'cheek_lift_shape', value: cheekValue, label: '提颧骨', onChange: setCheekValue },
            { name: 'nose_augment_shape', value: noseValue, label: '高鼻梁', onChange: setNoseValue },
            { name: 'lip_plump_differ_shape', value: lipValue, label: '丰唇', onChange: setLipValue },
        ]
    }, [eyeValue, faceValue, cheekValue, noseValue, lipValue])

    const bodyArray = useMemo(() => {
        return [
            { name: 'chest_plump', value: chestValue, label: '丰胸', onChange: setChestValue },
            { name: 'waist_slim', value: waistValue, label: '瘦腰', onChange: setWaistValue },
            { name: 'hip_plump', value: hipValue, label: '丰臀', onChange: setHipValue },
            { name: 'legs_slim', value: legsSlimValue, label: '瘦腿', onChange: setLegsSlimValue },
            { name: 'legs_lengthen', value: legsLengthenValue, label: '长腿', onChange: setLegsLengthenValue },
        ]
    }, [chestValue, waistValue, hipValue,legsSlimValue, legsLengthenValue])

    const jsStringPrefix = useMemo(() => {
        return {
            "face_modify_array": faceArray.map(item => ({ name: item.name, value: item.value })),
            "body_modify_array": bodyArray.map(item => ({ name: item.name, value: item.value })),
        }
    }, [faceArray, bodyArray])

    useEffect(() => {
        props.getfaceArray(faceArray)
        props.getbodyArray(bodyArray)
    }, [faceArray, bodyArray])

    useEffect(() => {
        if (!jsString) return
        const obj = JSON.parse(jsString)
        setJsString(JSON.stringify({
            ...obj,
            ...jsStringPrefix
        }))
    }, [jsStringPrefix])

    const initJsString = () => {
        isLoopRef.current = true
        return API.aibeautyPrediction({
            img_path: props.url,
            relight: 0,
        }).then(res => {
            if (res?.code != 0) throw new Error(res?.message)
            const predictionId = res.data
            const reqUrl = `${API.aibeautyPredictionUrl}/${predictionId}`
            return loopGetAibeauty(reqUrl, 200).then(res => {
                fetch(res)
                    .then(response => response.text())
                    .then(txtContent => {
                        let jsonTXT = JSON.stringify({
                            ...JSON.parse(txtContent),
                            ...jsStringPrefix
                        })
                        setJsString(jsonTXT)
                        setNFace(JSON.parse(txtContent).nFace)              
                    }).catch(err => {
                        console.error(err)
                        message.error('发生了错误'+err.message)
                    })
            })
        }).catch((err) => {
            // message.error(err.message)
            console.error('============', err.message)
        }).finally(() => {
            console.log('set loop false')
            isLoopRef.current = false
        })
    }

    const domReady = useMemo(() => {
        return canvasSize.width * canvasSize.height > 0
    }, [canvasSize])

    useEffect(() => {
        if (domReady) {
            if (!fabricInstanceRef.current) {
                if (!canvasRef_0.current) return
                // 马赛克
                const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
                const pattern = new fabric.Pattern({
                    source: patternImageBase64,
                    repeat: 'repeat',
                })
                const fabricImage = new fabric.Image(imgRef.current);
                fabricImage.scale(displayScale)
                const instance = new fabric.Canvas(canvasRef_0.current, {
                    width: canvasSize.width,
                    height: canvasSize.height,
                    backgroundImage: fabricImage,
                    backgroundColor: pattern,
                    stopContextMenu: true,
                })
                fabricInstanceRef.current = instance
                setFabricInstance(instance)
                addWatermark(instance) // 添加水印
            } else {
                const canvasScaleX = canvasSize.width / fabricInstanceRef.current.width
                const canvasScaleY = canvasSize.height / fabricInstanceRef.current.height
                const bgImage = fabricInstanceRef.current.backgroundImage
                if (bgImage) bgImage.scale(displayScale)
                
                const objs = fabricInstanceRef.current.getObjects()
                objs.forEach(item => {
                    item.set({
                        scaleX: item.scaleX * canvasScaleX,
                        scaleY: item.scaleY * canvasScaleY
                    })
                    item.setCoords()               
                })
                
                fabricInstanceRef.current.viewportTransform[4] = fabricInstanceRef.current.viewportTransform[4] * canvasScaleX
                fabricInstanceRef.current.viewportTransform[5] = fabricInstanceRef.current.viewportTransform[5] * canvasScaleY
                fabricInstanceRef.current.setDimensions({ width: canvasSize.width, height: canvasSize.height})
                fabricInstanceRef.current.renderAll()
            }
            if (genImg == originImg) initDrawImageOnCanvas()
            else reDrawImageOnCanvas()
        }
    }, [domReady, canvasSize, displayScale])

    // 窗口大小，原来触发resize事件
    const calcCanvasSize = () => {
        if (!imgRef.current) return
        const containerWidth = wrapRef.current?.clientWidth - 120 - 150
        const containerHeight = wrapRef.current?.clientHeight
        const size = Utils.getImgMaxSizeInContainer(
            imgRef.current.width,
            imgRef.current.height,
            containerWidth,
            containerHeight
        )
        displayScaleRef.current = size.width / imgRef.current.width
        setDisplayScale(size.width / imgRef.current.width)
        canvasSizeRef.current = {
            width: size.width,
            height: size.height
        }
        setCanvasSize({
            width: size.width,
            height: size.height
        })
    }
    
    useEffect(() => {
        const onWindowResize = (e) => {
            calcCanvasSize()
        }
        // 某些浏览器，比如火狐，页面初始化后立刻获得的dom尺寸不准确
        // 所以需要加个settimeout延迟获取
        // setTimeout(() => {
        //     calcCanvasSize()
        // }, 500)
        window.addEventListener('resize', onWindowResize)
        return () => {
            isMounted.current = false
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    const hasAlphaRef = useRef(false)
    useEffect(() => {
        // 美颜埋点
        Utils.useroplog(sessionStorage.getItem('oplog_imageType'), 'algorithm', 'aiBeauty', {'AI_edit_pic': props.url})
        if(props.url) {
            Utils.hasAlphaChannel(props.url).then(res => hasAlphaRef.current = res)
            loadImg(props.url)
                .then(loadModule)
                .then(calcCanvasSize)
            initJsString()
        }
    }, [])

    const loopGetAibeauty = (url, time=100) => {
        return new Promise((resolve, reject) => {
            const loopFunc = () => {
                axios.post(url, {}, {
                    headers: {
                        'Content-type' : 'application/json',
                        'Ps-Auth-Token': localStorage.getItem('token')
                    }
                }).then(res => {
                    if((res?.data?.code === 0 && res?.data?.data?.status === 'succeeded')) {
                        resolve(res.data.data.output[0])
                    } else if(res?.data?.code === 0 && res?.data?.data?.status == 'failed') {
                        const errCode = res.data.data.errcode
                        const errMsg = Utils.parseErrorCode(`[${res.data.data.errcode}]`).message
                        const error = Utils.parseErrorCode(`[${res.data.data.errcode}]`)
                        if (errCode == '71007') {
                            props.errorCallback()
                        } else {
                            eventBus.emit('notification', '提醒', error.message, 'error', error.code)
                        }
                        reject(new Error(`${errMsg}[${errCode}]`))
                    } else {
                        if (isMounted.current && isLoopRef.current) {
                            setTimeout(() => {
                                loopFunc()
                            }, time)
                        }
                    }
                })
            }
            loopFunc()
        })
    }

    const loadModule = async (pixels) => {
        let module = await createModule();
        setModule(module)
        module._init();

        const size = imgRef.current.width * imgRef.current.height * 3

        // console.log('memo size', size, imgRef.current)

        var pBuffer = module._malloc(size) // 通过emscripten分配C/C++中的堆内存

        module.writeArrayToMemory(pixels,pBuffer); //js中的数据拷贝到刚分配的C++缓存中

        module._setSize(imgRef.current.width, imgRef.current.height)
        module._fillArray(pBuffer,size)
        // 在此处执行您需要等待图像加载完成后才能执行的代码
    }

    const initDrawImageOnCanvas = () => {
        // 图像加载完成后，将Promise对象标记为已解决
        const canvas = document.createElement('canvas');
        // const canvas = canvasRef_i.current
        const ctx = canvas.getContext('2d');

        canvas.width = imgRef.current.width;
        canvas.height = imgRef.current.height;
        ctx.drawImage(imgRef.current, 0, 0, canvas.width, canvas.height);

        const imageData = ctx.getImageData(0, 0, imgRef.current.width, imgRef.current.height);

        const base64 = drawImageDataOnCanvas(imageData)
        originImgRef.current = base64
        setOriginImg(base64)
        genImgRef.current = base64
        setGenImg(base64)
        downloadUrl.current = null
    }

    const reDrawImageOnCanvas = () => {
        loadImg(genImg).then(res => {
            // 图像加载完成后，将Promise对象标记为已解决
            const canvas = document.createElement('canvas');
            // const canvas = canvasRef_i.current
            const ctx = canvas.getContext('2d');

            canvas.width = imgRef.current.width;
            canvas.height = imgRef.current.height;
            ctx.drawImage(imgRef.current, 0, 0, canvas.width, canvas.height);

            const imageData = ctx.getImageData(0, 0, imgRef.current.width, imgRef.current.height);

            const base64 = drawImageDataOnCanvas(imageData)
            genImgRef.current = base64
            setGenImg(base64)
            downloadUrl.current = null
        }).then(calcCanvasSize)
    }

    async function loadImg(url) {
        return new Promise(function(resolve, reject) {
            let img = new Image();
            img.crossOrigin = 'anonymous'; // 设置跨域属性，避免污染 canvas
            imgRef.current = img
            img.addEventListener('load', function() {
                // 图像加载完成后，将Promise对象标记为已解决
                const canvas = document.createElement('canvas');
                // const canvas = canvasRef_i.current
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
                const imageData = ctx.getImageData(0, 0, img.width, img.height);
                const data = imageData.data;
                const size = data.length / 4 * 3;
                // console.log('size:'+ size );
  
                const pixels = new Uint8Array(size);
  
                for (let i = 0, j = 0; i < data.length; i += 4, j += 3) {
                    pixels[j] = data[i+2];
                    pixels[j + 1] = data[i + 1];
                    pixels[j + 2] = data[i];
                }

                resolve(pixels);
            });
            img.addEventListener('error', function() {
                // 图像加载失败时，将Promise对象标记为已拒绝
                reject();
            });
            img.src = url;
        });
    }

    function changeBeauty() {
        const size =imgRef.current.width * imgRef.current.height * 3;       
        //   const jsString = jsString;
        const strptr = module.stringToNewUTF8(jsString);
    
        module._setSize(imgRef.current.width, imgRef.current.height)

        let resimg = module._processPortraitEdit(strptr,jsString.length+1);

        if(resimg) {
                var bgr = new Uint8Array(module.HEAPU8.buffer, resimg, size);

                const fabricImage = fabricInstanceRef.current.backgroundImage 
                // 创建一个临时的Canvas元素
                var tempCanvas = document.createElement('canvas');
                var tempContext = tempCanvas.getContext('2d');
                // 设置Canvas的宽度和高度与Fabric.Image对象相匹配
                tempCanvas.width = fabricImage.width * imgRef.current.width / fabricImage.width;
                tempCanvas.height = fabricImage.height * imgRef.current.width / fabricImage.width;
                // 渲染Fabric.Image对象到Canvas上
                fabricImage.render(tempContext);
                // 获取Canvas上的图像数据
                var imageData = tempContext.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
                const data = imageData.data;
    
                for (let i = 0, j = 0; i < data.length; i += 4, j += 3) {
                    data[i] = bgr[j+2];
                    //data[i] =255;
                    data[i + 1] = bgr[j + 1];
                    data[i + 2] = bgr[j];
                    data[i + 3] = 255;
                }
                // console.log('imageData', imageData)
                // ctx_0.putImageData(imageData, 0, 0);
                const base64 = drawImageDataOnCanvas(imageData)
                genImgRef.current = base64
                setGenImg(base64)
                downloadUrl.current = null
        }
    }

    // 传入一个imageData，将其先改变成512，然后使用drawImage绘制，putImageData不能直接设置宽高
    function drawImageDataOnCanvas(imageData) {
        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
      
        const context = canvas.getContext('2d');
        context.putImageData(imageData, 0, 0);

        const img = new fabric.Image(canvas)
        img.scale(canvasSize.width / imageData.width)

        fabricInstanceRef.current.setBackgroundImage(img, () => {
            fabricInstanceRef.current.renderAll()
        })

        const base64 = canvas.toDataURL(hasAlphaRef.current ? 'image/png' : 'image/jpeg')
        return base64
    }

    useEffect(() => {
        if (!jsString) return
        if (!fabricInstance) return
        if (!initialized) {
            setInitialized(true)
            return
        }
        // 由于半脸情况下重置，算法会失效，这里强制恢复原图临时处理一下 202312091646
        const obj = JSON.parse(jsString)
        const face_modify_array = obj['face_modify_array']
        const body_modify_array = obj['body_modify_array']
        if (!face_modify_array.find(item => item.value != 0) && !body_modify_array.find(item => item.value != 0)) {
            const img = new fabric.Image(imgRef.current)
            img.scale(displayScaleRef.current)
            fabricInstanceRef.current.setBackgroundImage(img, () => {
                fabricInstanceRef.current.renderAll()
            })
            genImgRef.current = originImgRef.current
            setGenImg(originImgRef.current)
            downloadUrl.current = null
        } else {
            changeBeauty()
        }
    }, [jsString, fabricInstance])

    const oneClickBeauty = () => {
        // saveUndoList()
        // faceArray.forEach(item => item.onChange(Math.floor(Math.random() * 100)))
        // bodyArray.forEach(item => item.onChange(Math.floor(Math.random() * 100)))
        if(nFace == 1) {
            setEyeValue(20)
            setFaceValue(30)
            setCheekValue(-20)
            setNoseValue(30)
            setLipValue(30)
        }
        
        setChestValue(30)
        setWaistValue(50)
        setHipValue(50)
        setLegsSlimValue(60)
        setLegsLengthenValue(60)
    }

    const downloadUrl = useRef()
    const commonDownloadFunc = async () => {
        if (downloadUrl.current) {
            Utils.downUrl(downloadUrl.current, sessionStorage.getItem('oplog_imageType'), false)
            message.success('下载成功')
        } else {
            downloadUrl.current = await aiEditDownloadWithLoadingFunc(
                genImg,
                AIEDIT_SOURCE.AI_BEAUTY,
                sessionStorage.getItem('oplog_imageType'),
                needPay
            )
            if (downloadUrl.current) setNeedPay(false)
        }
    }

    const revoke = () => {
        setUndoList(pre => {
            const actions = pre.pop()
            if (!actions) return
            setRestoreList(pre => [...pre, [
                ...faceArray, ...bodyArray
            ]])
            for (let item of actions) {
                item.onChange(item.value)
            }
            return pre
        })
    }

    const restore = () => {
        setRestoreList(pre => {
            const actions = pre.pop()
            if (!actions) return
            setUndoList(pre => [...pre, [
                ...faceArray, ...bodyArray
            ]])
            for (let item of actions) {
                item.onChange(item.value)
            }
            return pre
        })
    }

    const reset = () => {
        setShowCompare(false)
        setRestoreList([])
        setUndoList([])
        const arr = [...faceArray, ...bodyArray]
        for (let item of arr) {
            item.onChange(0)
        }
        dragAndScaleRef.current.resetCanvas() // 重置
    }

    const saveUndoList = () => {
        setUndoList(pre => [...pre, [
            ...faceArray, ...bodyArray
        ]])
    }

    const handleCompare = () => {
        if (!showCompare) {
            getImgWithWatermark(genImg).then(base64 => {
                setGenImgWithWatermark(base64)
                setShowCompare(true)
            })
        } else {
            setShowCompare(false)
        }
    }

    const rightContent = (
        <div className='edit_right_c' style={{ margin: '0 auto', position: 'relative' }} ref={wrapRef}>
            <div className='wrap_box wrap_box_'>
                {
                    domReady ? 
                    <div className='dragAndScale_box'>
                    {
                        fabricInstance ? 
                        <DragAndScale
                            ref={dragAndScaleRef}
                            min={1}
                            max={8}
                            canvasList={[fabricInstance]}
                            disabled={showCompare? true: false}
                            options={[
                                { label: '适应屏幕', value: 1 },
                                // { label: '实际大小', value: Number((1 / displayScale).toFixed(2)) },
                                { label: '100%', value: 1 },
                                { label: '200%', value: 2 },
                                { label: '300%', value: 3 },
                                { label: '400%', value: 4 },
                            ]}
                        /> :
                        null
                    }
                    {
                        showCompare?<p>对比时可直接使用滚轮缩放、按住鼠标移动</p>: null
                    }
                    </div> : ""
                }
                {
                    domReady ?
                    showCompare &&
                    <div className="compare_wrap" style={{ ...canvasSize }}>
                        <ImgComparer
                            leftImage={originImg}
                            rightImage={genImgWithWatermark}
                        />
                    </div> : ""
                }
                <div
                    style={{
                        position: showCompare ? 'absolute' : 'relative',
                        top: showCompare ? '-9999px' : '0',
                        left: showCompare ? '-9999px' : '0',
                        ...canvasSize
                    }}
                >
                    <canvas ref={canvasRef_0} />
                </div>
                {
                    domReady ?
                    <ToolBox
                        showCompare={showCompare}
                        compare={{disabled: originImg == genImg, trigger: handleCompare}}
                        revoke={{disabled: undoList.length == 0, trigger: revoke}}
                        restore={{disabled: restoreList.length == 0, trigger: restore}}
                        reset={{disabled: originImg == genImg, trigger: reset}}
                        download={{disabled: originImg == genImg, trigger: commonDownloadFunc}}
                        uploadCb={props.uploadCb}
                    /> : ""
                }
                {
                    !domReady ?
                    <div style={{display:"flex", justifyContent:"center", alignItems: "center", height: "100%"}}>
                        <Spin size="large" />
                    </div> : ""
                }
            </div>
        </div>
    )

    return (
        <div className='edit_cont'>
            <div className='edit_right'>
            {
                !props.url ?
                props.defaultPage:
                rightContent
            }                
            </div>
        </div>
    )
})

export default AIBeauty;
