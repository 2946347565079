import styles from './satisfactionSurvey.module.scss'
import { Modal, Input, message } from "antd"
import { CloseOutlined } from '@ant-design/icons';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import API from '@api/api.js';
import { AppContext } from '@utils/AppContext';
import {IMAGE_TYPE} from '@utils/CONST'
import PSAIModal from '@PSAIComponents/PSAIModal';
import Utils from '@utils/utils';
import GroupTitleContent from '../GroupTitleContent';

const { TextArea } = Input;

const SatisfactionSurvey = (props) => {
    const { forceUpdate } = useContext(AppContext);
    const [discontentModel, setDiscontentModel] = useState(false)
    const [discontentSelect, setDiscontentSelect] = useState([])
    const [comments, setComments] = useState()
    const discontentListRef = useRef(['人体残缺', '面部扭曲', '模特不够好看', '模特不符合需求', '场景不符合需求', '场景不够美观'])
    const scoreListRef = useRef([
        {src: require(`@assets/images/icon_satisfaction_good.png`), text: '满意'},
        {src: require(`@assets/images/icon_satisfaction_normal.png`), text: '一般'},
        {src: require(`@assets/images/icon_satisfaction_bad.png`), text: '不满意'}
    ])
    
    useEffect(() => {
        return () => {
            setDiscontentModel(false)
        }
    }, [])

    const groupTitle = useMemo(() => {
        if (props.imageType == IMAGE_TYPE.MODEL_VIDEO) return ""//"动作"
        if (props.imageType == IMAGE_TYPE.COMMODITY_VIDEO) return '分辨率'
        return props.imageType == IMAGE_TYPE.COMMODITY ? '场景组合' : '模特场景组合'
    }, [props.imageType])

    const [idList, setIdList] = useState({
        taskId: '',
        imageId: ''
    })

    const selectClick = (item) => {
        if (discontentSelect.includes(item)) {
            setDiscontentSelect((prev) => prev.filter((selectedItem) => selectedItem !== item));
        } else {
            setDiscontentSelect((prev) => [...prev, item]);
        }
    }
    const scoreImageClick = (score, index, taskId, imageId) => {
        if(score !== '') { //禁止修改评价
            return false
        }
        if(index === 0 || index === 1) {
            scoreImageAPI(taskId, imageId, index)
        } else {
            setDiscontentModel(true)
            setIdList(({
                taskId: taskId,
                imageId: imageId
            }))
        }
    }

    const scoreImageAPI = (taskId, imageId, score, reason, comments) => {
        API.scoreImage({
            taskId: taskId,
            imageId: imageId,
            score: score,
            reason: reason || '',
            comments: comments || '',
        }).then((res) => {
            if (res?.code === 0) {
                setDiscontentModel(false)
                forceUpdate({})
                message.success('完成评价！')
            } else {
                message.error('评价失败！');
            }
        }).finally(() => {
        })
    }

    const commentsChange = (e) => {
        setComments(e.target.value)
    }

    const submitClick = () => {
        scoreImageAPI(idList.taskId, idList.imageId, 2, discontentSelect, comments)
    }

    // 启用自定义提示词
    const isCustomScene = useMemo(() => {
        return [IMAGE_TYPE.COMMODITY, IMAGE_TYPE.MODEL].includes(props.imageType) && (props.definedFlag == 1)
    }, [props.definedFlag])

    const showGroupTitle = useMemo(() => {
        if (!groupTitle) return false
        if (props.imageType == IMAGE_TYPE.COMMODITY) {
            return !isCustomScene
        } else {
            return true
        }
    }, [isCustomScene, groupTitle])

    // 定制模特
    const isCustomModel = useMemo(() => {
        return (props.imageType == IMAGE_TYPE.CLOTHES) && props.refImg
    }, [props.refImg])

    return (
        <>
        <div className={styles.image_preview_custom_describe}>
            <div className={styles.title_box}>
                <p className={styles.describe_title}>{props.projectName}</p>
            </div>
            <div className={`${styles.top_box} _beauty_scrollbar`}>
            {
                showGroupTitle &&
                <>
                    <p className={styles.describe_title}>{groupTitle}：</p>
                    <p className={styles.describe_bottom}>
                        <GroupTitleContent
                            useReal
                            showImg={false}
                            imageType={props.imageType}
                            personTitle={props.personTitle}
                            personRealTitle={props.personRealTitle}
                            backgroundTitle={props.backgroundTitle}
                            backgroundRealTitle={props.backgroundRealTitle}
                            videoTryonTitle={props.videoTryonTitle}
                            refImg={props.refImg}
                            definedFlag={props.definedFlag}
                            keepPose={props.keepPose}
                            keepBkg={props.keepBkg}
                        />
                    </p>
                </>
            }
            <p className={styles.describe_title}>生成时间：</p>
            <p className={styles.describe_bottom}>{props.updatedTime}</p>
            {
                isCustomScene &&
                props.prompt && <>
                    <p className={styles.describe_title}>场景描述词：</p>
                    <p className={styles.describe_bottom}>{props.prompt}</p>
                </>
            }
            {
                isCustomScene &&
                props.refImg &&
                <>
                    <p className={styles.describe_title}>自定义参考图：</p>
                    <div className={styles.describe_ref_img} >
                        <img src={Utils.getImgUrlWithWebp(props.refImg, true)} />
                    </div>
                </>
            }
            {
                isCustomModel &&
                <>
                    <p className={styles.describe_title}>自定义模特：</p>
                    <div className={styles.describe_ref_img} >
                        <img src={Utils.getImgUrlWithWebp(props.refImg, true)} />
                    </div>
                </>
            }
            {
                (props.imageType == IMAGE_TYPE.MODEL_VIDEO || props.imageType == IMAGE_TYPE.COMMODITY_VIDEO) &&
                <>
                    <p className={styles.describe_title}>输入图：</p>
                    <div className={`${styles.describe_ref_img} ${props.inputImage.split(',').length > 1 && styles.multiple_img}`} >
                    {
                        props.inputImage.split(',').map(item => (
                            <img key={item} src={Utils.getImgUrlWithWebp(item, true)} />
                        ))
                    }
                    </div>
                </>
            }
            </div>
            <div className={styles.bottom_box}>
                <p>您对当前结果是否满意：</p>
                <p className={styles.describe_score_item}>
                    {
                        scoreListRef.current.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    style={{background: props.score === index? '#eff2fd': 'none' }}
                                    onClick={() => scoreImageClick(props.score, index, props.taskId, props.id)}
                                >
                                    <img src={item.src} alt="" />
                                    <span>{item.text}</span>
                                </span>
                            )
                        })
                    }
                </p>
            </div>
        </div>
        <PSAIModal
            title="请选择不满意的原因"
            getContainer={document.getElementsByClassName('image-preview-custom-wrapper')[0]}
            open={discontentModel}
            maxWidth={800}
            onCancel={() => {setDiscontentModel(false); setDiscontentSelect([])}}
            onText="提交"
            onOk={submitClick}
        >
            <div className={styles.discontent_count}>
                <p className={styles.discontent_count_p}>
                    {
                        discontentListRef.current.map((item, index) => {
                            const isSelected = discontentSelect.includes(item);
                            return <span key={index} onClick={() => selectClick(item)} className={`${styles.discontent_count_select} ${isSelected && styles.discontent_count_selected}`}>{item}</span>
                        })
                    }
                </p>
                <p style={{margin: '10px 0', fontSize: '16px'}}>其他问题</p>
                <TextArea style={{marginBottom: '20px'}} onChange={commentsChange} rows={6} placeholder="任何让您不满意的原因都请写在这边" maxLength={300} showCount />
                {/* <div className="btn-submit" onClick={submitClick}>提 交</div> */}
            </div>
        </PSAIModal>
        </>
    )
}

export default SatisfactionSurvey